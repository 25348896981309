import React , { useState } from 'react'
import { Link , useLocation } from 'react-router-dom';
import styles from './DocRow.module.scss';
import { format } from 'date-fns';
import { IoClose } from 'react-icons/io5'
import { IoNewspaperOutline , IoFlaskOutline  } from 'react-icons/io5';
import { VscFile } from 'react-icons/vsc';

export default function DocRow({ doc , index , isPreview  , handleChange }) {

  const [ isDeleted , setIsDeleted ] = useState(false);

  const location = useLocation();

  function renderIcon(type) {
    if (type === "publication") return (<IoNewspaperOutline size={24} />)
    if (type === "trial") return (<IoFlaskOutline size={25} />)
    return (<VscFile size={24} />)
  }

  function removeDocument(id) {
    setIsDeleted(true);
    handleChange(id)
  }

  function renderLink() {
    return (
      <>
      {doc.type === "intern" ?
        <Link to={`/library/intern/${doc._id}/${location.search}`}>
          <p>{doc?.title.length >= 90 ? `${doc.title.slice(0, 90)} ...` : doc.title }</p>
        </Link>
        :
        <a href={doc.url} target="_blank" rel="noreferrer">
          <p>{doc?.title.length >= 90 ? `${doc.title.slice(0, 90)} ...` : doc.title }</p>
        </a >
      }
    </>
    ); 
  }

  return (
    <>
     {doc?.title &&
        <div className={styles.container} style={{ animationDelay: .08 * index + 's'}}>
          <div className={styles.icon}>
            {renderIcon(doc.type)}
          </div>
          <div className={`${styles.title} ${isPreview ? styles.preview : ""}`}>
            {renderLink()}
          </div>
          <div className={styles.info}>
            <p>{doc?.authors?.length > 0 ? doc.authors?.length : 0 } authors</p>
          </div>
          <div className={styles.info}>
            <p>{format(new Date(doc.publicationDate) , 'yyyy/MM/dd')}</p>
          </div>
          {!isPreview &&
            <button
              onClick={() => removeDocument(doc._id)}
              className={`${styles.close} ${isDeleted ? styles.deleted : ""}`}>
              <IoClose size={20}/>
            </button>
          }
        </div>
      }
    </>
  )
}
