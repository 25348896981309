import React , { useRef } from 'react';
import styles from './AuthorRow.module.scss';

import { AiOutlineUser } from 'react-icons/ai';
import { useGlobalContext } from '../../../context/GlobalContext';
import { getAuthorAction } from '../../../actions/recordsActions';

export default function AuthorRow({ author , count , isTag = false, index }) {
  const dispatch = useGlobalContext()[1];
  const authorRef = useRef();

  return (
    <div
      ref={authorRef}
      className={styles.container}
      onClick={() => getAuthorAction(dispatch,{ name: author })}
      style={{ animationDelay: .02 * index + 's'}}
    >
      <div className={styles.title}>
        <div className={styles.icon}>
          <AiOutlineUser size={24} />
        </div>
        <p className={isTag ? styles.selected : 0}>{author}</p>
      </div>
      <div className={styles.info}>
        {count} publication{count > 1 && 's'}
      </div>
    </div>
  )
}
