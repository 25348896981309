import React , { useEffect , useState , useRef } from 'react';
import { useSpring, animated } from 'react-spring';
//Style
import styles from './Timeline.module.scss';

//golbal context
import { useGlobalContext } from '../../context/GlobalContext';

//Components
import Loader from '../../components/lib/Loader/Loader';
import Select from '../../components/lib/Select/CustomSelect';
import TimelineRow from '../../components/partials/TimelineRow/TimelineRow';
import DatavizLoader from '../../components/partials/DatavizLoader/DatavizLoader';

export default function LibraryPage() {

  const [ context , dispatch ]  = useGlobalContext();
  const { query } = context.records;
  const timeline = context.dataviz?.timeline;

  const datesData = context.records?.dates;
  const containerRef = useRef(null);
  const scrollRef = useRef(null);
  // const initSponsorsOpt = { value: "all" , label: "ALL SPONSORS"}

  const [ filtredList , setFiltredList ] = useState([]);
  const [ isLoading , setIsLoading ] = useState(true);
  const [ filtredDates , setFiltredDates ] = useState(null)
  const [ sponsorsOpt, setSponsorsOptions ] = useState([]);
  const [ selectedSponsors, setSelectedSponsors ] = useState([]);
  const [ dates , setDates ] = useState();
  const [ scrollX , setScrollX] = useState(0);

  const animatedCount = useSpring({ number: filtredList.length , from: { number: 0 } });
  const monthWidth = 40 ;
  const dayWidth = monthWidth * 12 / 365;

  useEffect(() => {
    if (datesData?.newest && datesData?.oldest) {
      let datesList = [];
      let date = new Date(datesData.oldest.publicationDate);
      date = new Date(date.setDate(1));
      while (date <= new Date(datesData.newest.publicationDate)) {
        date = new Date(date.setMonth(date.getMonth()+1));
        datesList.unshift(date.toISOString());
      }
      setDates(datesList);
    } 
  }, [datesData])

  useEffect(() => {
    if (timeline && dates) {
      let startDate = null
      let endDate = new Date(query.end);
      let sponsors = [];

      let trials = timeline.filter(t => t.content.start_date && t.content.completion_date ).sort((a,b)=> new Date(a.content.start_date).getTime() - new Date(b.content.start_date).getTime());
      
      trials.forEach(t => {
        t?.content?.sponsors?.forEach(s => {
          if(!sponsors.find((sp) => sp.value === s)) {
            sponsors.push({ value: s , label: s })
          }
        })
      });

      trials = trials.filter(t => {
        if (selectedSponsors?.length === 0) {
          return t
        }
        if(selectedSponsors && t.content.sponsors.find((sp) => selectedSponsors.find((s) => s.value === sp))) {
          return t
        }
        return null;
      });

      setSponsorsOptions(sponsors);
      
      trials.forEach(t => {
        if (!startDate) startDate = new Date(t.content.start_date).setDate(1)
        if (startDate && startDate > new Date(t.content.start_date)) startDate = new Date(t.content.start_date).setDate(1)
        if (!endDate) endDate = new Date(t.content.completion_date).setDate(1)
        if (endDate && endDate < new Date(t.content.completion_date)) endDate = new Date(t.content.completion_date).setDate(1)
      });


      if(!startDate) startDate = new Date(query.start)

      const dateslist = dates?.filter(d => {
        let date = new Date(d).getTime() ;
        if (date >= new Date(startDate).getTime()  && date < new Date(endDate).getTime() ) {
          return d
        } else {
          return null;
        }
      }).reverse();

      setFiltredDates(dateslist);
      
      trials.forEach(( t , i ) => {
        let offsetFromStart = datediff(new Date(dateslist[0]), new Date(t.content.start_date))
        let durationInDays = datediff(new Date(t.content.start_date), new Date(t.content.completion_date))
        t.style = { transform: `translateX(${dayWidth * offsetFromStart}px)` , width: durationInDays * dayWidth }
      });
      setFiltredList(trials);
      containerRef.current.scrollTo({top: 0 , left: 0 ,  behavior: 'smooth'});
      dispatch({
        type: 'LIBRARY_IS_LOADING',
        payload: false
      })
      setIsLoading(false);
      
    }
    // eslint-disable-next-line
  }, [timeline , dates , selectedSponsors ]);

  function datediff(first, second) {
    return Math.round((second-first)/(1000*60*60*24));
  }

  function handleScroll(e) {
    setScrollX(e.target.scrollLeft)
  }

  function goToMonth(index) {
    const value = index * monthWidth
    containerRef.current.scrollTo({top: 0 , left: value ,  behavior: 'smooth'});
  }


  return (
    <div className={`page-container ${styles['page-container']}`}>
      <DatavizLoader
        isLoading={context.dataviz.isLoading}
        className={styles.loader}
        />
      <header className={styles.header}>
        <div className={styles.content}>
          <div className={styles.select}>
            {selectedSponsors &&
              <Select
                defaultValue={selectedSponsors}
                placeholder={"Select a sponsor"}
                isSearchable
                isMulti
                handleChange={(array) => setSelectedSponsors(array)}
                options={sponsorsOpt}
                />
            }
          </div>
          <p><animated.span>{animatedCount.number.interpolate(x => x.toFixed(0))}</animated.span> Trials</p>
        </div>
      </header>
      <div
        className={`${styles.container}`}
        onScroll={(e) => handleScroll(e)}
        ref={containerRef}
        >
        <div
          style={{ width: filtredDates?.length > 0 ? filtredDates.length * monthWidth : 0}}
          className={`content ${styles.content}`}>
          <div
          ref={scrollRef}
          >
          {filtredList?.map((doc , i ) => {
              return <TimelineRow key={"timeline" + doc._id } index={i} doc={doc} />
          })}
          {isLoading && 
            <div className={styles.loader}>
              <Loader/>
            </div>
          }
          </div>
        </div>
      </div>
      <div
        className={styles['dates-list']}
        >
      {filtredDates?.map((d ,i ) =>
        <div
          key={'date' + i }
          style={{ transform: `translateX(-${scrollX}px)` , width: monthWidth }}
          className={`${styles.year} ${new Date(d).getMonth() === 0 ? styles.new :""}` }>
          <div className={styles.bar}></div>
          <p
            onClick={() => goToMonth((i))}
            >
            {new Date(d).getMonth() === 0 ?
             <span>{new Intl.DateTimeFormat("fr" ,{ month: "short" }).format(new Date(d))}<br/>{new Intl.DateTimeFormat("fr" ,{ year: "numeric" }).format(new Date(d))}</span>
              :
              <span>{new Intl.DateTimeFormat("fr" ,{ month: "short" }).format(new Date(d))}</span>
            }
          </p>
        </div>
      )}
      </div>
    </div>
  )
}