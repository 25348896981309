import React from 'react';
import './tooltip.scss';

export default function Tooltip({ data , type }) {

  return (
    <div
      className={`tooltip ${type}`}
      style={{
        left: data.x ,
        top: data.y ,
        border: `2px solid ${data.color ? data.color : "transparent" }`,
        opacity: data.size ? 1 : 0 
      }}>
      {data && data.city && type === "geography" &&
        <p>
          <span className="code">{data.city}</span><br/>
          <span style={{ color: data.color }}>{data?.size} Documents</span><br/>
        </p>
      }
       {data && type === "datavision" &&
        <p>
          <span className="code">{data.value}</span><br/>
          <span style={{ color: data.color }}>{data?.size} Documents</span><br/>
        </p>
      }
    </div>
  )
}
