import React , { useState , useEffect , useCallback } from 'react';
import { NavLink , useLocation } from 'react-router-dom';
import { FiLogOut , FiUser } from "react-icons/fi";
import * as QueryString from "query-string";

import { useGlobalContext } from '../../../context/GlobalContext';

import { getRecordsFilteredAction , getAuthorsFilteredAction , postOptionsListsAction , getTopicsAction } from '../../../actions/recordsActions';
import { getReadingGuidesFilteredAction } from '../../../actions/readingGuidesActions';
import { getLocationsFilteredAction , getDashboardFilteredAction , getTimelineFilteredAction, getDatavisionFilteredAction } from '../../../actions/datavizActions';
import Filters from '../../partials/Filters/Filters';

import logo from '../../../assets/images/logo.svg';

import styles from './Nav.module.scss';

export default function Nav({ logout }) {

  const [ isHidden , setIsHidden ] = useState(true);
  const [ context , dispatch ] = useGlobalContext();
  const [ savedQuery , setSavedQuery ] = useState();
  const { query , optionsLists } = context.records;
  const location = useLocation();
  let queryFilters = QueryString.parse(location.search);

  const initData = useCallback(() => {
    getTopicsAction(dispatch);
  },[dispatch]);

  useEffect(() => {
    initData();
  }, [initData]);

  useEffect(() => {
    if (location.pathname === "/login" || location.pathname === "/index" || location.pathname?.includes("/sso") || location.pathname === "/topics" || location.pathname === "/" ) {
      setIsHidden(true);
    } else {
      setIsHidden(false);
    }
  }, [location.pathname]);

  useEffect(() => {
    if (location.search) {
      setSavedQuery(location.search);
      let data = {...queryFilters};
      data.topic = queryFilters.topic.split(',');
      data.type = queryFilters?.type?.length > 0 ? queryFilters.type.split(',') : null;
      data.cities = queryFilters?.cities?.length > 0 ? queryFilters.cities.split(',') : null;
      data.countries = queryFilters?.countries?.length > 0 ? queryFilters.countries.split(',') : null;
      data.keywords = queryFilters?.keywords?.length > 0 ? queryFilters.keywords.split(',') : null;
      data.authors = queryFilters?.authors?.length > 0 ? queryFilters.authors.split(',') : null;
      data.number = parseInt(data.number)
      data.page = parseInt(data.page);
      if (!savedQuery) {
        data.page = 1
        data.number = 20
      }
      if (location.pathname !== "/library/" && location.pathname !== "/author/" ) {
        data.page = 1
        data.number = 20
      }
      dispatch({
        type: 'ERROR_RECORDS',
        payload: null
      });

      dispatch({
        type: 'LIBRARY_IS_LOADING',
        payload: true
      });

      dispatch({
        type: 'SET_QUERY',
        payload: data
      });
      postOptionsListsAction(dispatch , data );
    }

    // eslint-disable-next-line
  }, [location.search]);

  useEffect(() => {
    if (query.topic.length > 0) {
      if (location.pathname === "/author/") {
        getAuthorsFilteredAction(dispatch , query );
      }
      if (location.pathname === "/library/") {
        getRecordsFilteredAction(dispatch , query);
        getReadingGuidesFilteredAction(dispatch , query);
      }
      if (location.pathname === "/map/") {
        dispatch({ type: "DATAVIZ_IS_LOADING" , payload: true });
        getLocationsFilteredAction(dispatch , query );
      }
      if (location.pathname === "/dashboard/") {
        dispatch({ type: "DATAVIZ_IS_LOADING" , payload: true });
        getDashboardFilteredAction(dispatch , query );
      }
      if (location.pathname === "/datavision/") {
        dispatch({ type: "DATAVIZ_IS_LOADING" , payload: true });
        getDatavisionFilteredAction(dispatch , query );
      }
      if (location.pathname === "/timeline/") {
        dispatch({ type: "DATAVIZ_IS_LOADING" , payload: true });
        getTimelineFilteredAction(dispatch , query );
      }
    }
    // eslint-disable-next-line
  },[query, location.pathname]);

  
  function setLibraryView() {
    dispatch({
      type: 'SET_LIBRARY_VIEW',
      payload : "documents"
    });
  }

  return (
    <>
      <nav id="nav"  className={`${styles.nav} ${isHidden ? "" : styles['nav-is-active']}`}>
        <div className={styles.content}>

          <NavLink to="/topics">
            <img src={logo} alt="medocta" />
          </NavLink>

          <div className={styles.links}>
            <NavLink to={"/dashboard/" + location.search } activeClassName={styles['is-active']} >HOME</NavLink>
            <NavLink to={"/datavision/" + location.search } activeClassName={styles['is-active']} >Network</NavLink>
            <NavLink to={"/map/" + location.search } activeClassName={styles['is-active']} >GEOGRAPHY</NavLink>
            <NavLink to={"/timeline/" + location.search } activeClassName={styles['is-active']} >Clinical Trials</NavLink>
            <NavLink to={"/library/" + location.search } onClick={() => setLibraryView()} activeClassName={styles['is-active']} >LIBRARY</NavLink>
            <NavLink to={"/author/" + location.search } activeClassName={styles['is-active']} >AUTHORS</NavLink>
          </div>
          
          <div className={styles.icons}>

            <NavLink to="/account">
              <FiUser size={22} color={"#192243"}/>
            </NavLink>

            <button className={styles.logout} onClick={() => logout()}>
              <FiLogOut size={22} color={"#192243"}/>
            </button>

          </div>

        </div>
      </nav>
      <div id="filters" className={`${styles.filters} ${isHidden ? "" : styles['is-active']}`}>
        {location.pathname !== "/account" && optionsLists && <Filters isDisabled={isHidden} pathname={location.pathname}/>}
      </div>
    </>

  )
}
