import React , { useEffect , useState  , useCallback } from 'react';
import { useHistory , useParams , Link , useLocation } from 'react-router-dom';
import { RiArrowLeftSLine } from "react-icons/ri";
//Style
import styles from './InternalRecord.module.scss';

//golbal context
import { useGlobalContext } from '../../context/GlobalContext';
import { getRecordAction , getFileAction , deleteRecordAction } from '../../actions/recordsActions';
//Components
import Button from '../../components/lib/Button/Button';
import Modale from '../../components/lib/Modale/Modale';
import Loader from '../../components/lib/Loader/Loader';
import PdfReader from '../../components/lib/PdfReader/PdfReader';
import DocumentModale from '../../components/partials/DocumentModale/DocumentModale';


export default function InternalRecordPage() {

  const [ context , dispatch ] = useGlobalContext();
  const history = useHistory();
  const location = useLocation();
  const { docId } = useParams();
  const { internalRecord , filePreview } = context.records;
  const { isAdmin , toast } = context.auth

  const initData = useCallback(() => {
    getRecordAction(dispatch , docId )
  },[dispatch , docId]);

  let timeline = document.getElementById("timeline")?.style;

  const [ activeModale , setActiveModale ] = useState();

  useEffect(() => {
    initData();
    if(timeline) {
      timeline.opacity = 0
      timeline.pointerEvents = 'none'
    }
    return () => {
      timeline.opacity = 1
      timeline.pointerEvents = 'all'
      setActiveModale();
    }
  }, [initData , timeline]);

  useEffect(() => {
    if(timeline) {
      timeline.opacity = 0
      timeline.pointerEvents = 'none'
    }
  },[activeModale, timeline]);

  useEffect(() => {
    if (internalRecord?.file) {
      getFileAction(dispatch , internalRecord.file , true );
    }
    // eslint-disable-next-line
  },[internalRecord])

  useEffect(() => {
    if (toast?.message === "The document has been removed") {
      history.push('/library/' + location.search )
    }
    // eslint-disable-next-line
  },[toast]);

  function deleteDocument() {
    deleteRecordAction(dispatch , internalRecord._id)
  }

  return (
    <>
    <div className={`page-container ${styles['page-container']}`}>
      <header className={styles.header}>
        <div className={styles.content}>
          <div className={styles.links}>
            <Link to={'/library/' + location.search }>
              <RiArrowLeftSLine size={26} color={"#20B9BC"}/>
              <p>back to the list</p>
            </Link>
          </div>
          <div className={`${styles.actions}`}>
            {isAdmin &&
              <>
                {internalRecord?.file &&
                  <Button
                    primary
                    onClick={() => getFileAction(dispatch , internalRecord.file )}
                    >
                    Download
                  </Button>
                }
                <Button
                  primary
                  onClick={() => setActiveModale('document')}
                  >
                  Edit
                </Button>
                <Button
                  danger
                  onClick={() => deleteDocument()}
                  >
                  Delete
                </Button>
              </>
            }
          </div>
        </div>
      </header>
      <div className={`${styles.container}`}>
        <div className={`content ${styles.content}`}>
          <h1>{internalRecord?.title}</h1>
          <h2>{internalRecord?.topic}</h2>
          <div className={styles.keywords}>
            {internalRecord?.keywords?.map((k ,i ) => <p key={'keyword' + i}>{k}</p>)}
          </div>
          {internalRecord?.content?.description &&
          <div className={styles.description}>
            <label>Description</label>
            <p>
              {internalRecord?.content?.description}
            </p>
          </div>
          }
          {filePreview && <PdfReader file={filePreview} />}
          {!filePreview && <Loader />}
        </div>
      </div>
    </div>

    <Modale
      isOpen={activeModale}
      modaleToggle={setActiveModale}
      hideClose={true}
      >
      {activeModale === "document" && <DocumentModale
        documentData={internalRecord}
        setActiveModale={setActiveModale}
        />}
    </Modale>
    </>
  )
}