import 'date-fns';
import React , { useState } from 'react';
import DateFnsUtils from '@date-io/date-fns';
import frLocale from "date-fns/locale/fr";
import { createMuiTheme } from "@material-ui/core";
import { ThemeProvider } from "@material-ui/styles";

import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker ,
} from '@material-ui/pickers';

import './datePicker.scss'

export default function CustomDatePicker({ label , id , handleChange , date , withErrors ,error }) {

  const [selectedDate, setSelectedDate] = useState(date ? date : null );

  const primaryColor = '#20B9BC';

  const materialTheme = createMuiTheme({
    overrides: {
      MuiInputLabel: {
        shrink: false
      },
      MuiInputUnderline : {
        display: 'none'
      },
      MuiPickersToolbar: {
        toolbar: {
          backgroundColor: primaryColor,
        },
      },
      MuiPickersCalendarHeader: {
        switchHeader: {

        },
      },
      MuiPickersDay: {
        daySelected: {
          backgroundColor: primaryColor,
        },

      },
      MuiPickersModal: {
        dialogAction: {
          color: primaryColor,
        },
      },
    },
  });

  const handleDateChange = (date) => {
    setSelectedDate(date);
    handleChange(date)
  };

  return (
    <>
      <label>{label}</label>
      <div className="date-picker">
        <ThemeProvider theme={materialTheme}>
          <MuiPickersUtilsProvider locale={frLocale} utils={DateFnsUtils}>
            <KeyboardDatePicker
              variant="inline"
              format="dd/MM/yyyy"
              margin="normal"
              id={id ? id : "date-picker-inline"}
              value={selectedDate}
              InputProps={{
                disableUnderline: true,
                // disableAnimation:true
              }}
              invalidDateMessage={"la date est invalide"}
              
              onChange={handleDateChange}
            />
          </MuiPickersUtilsProvider>
        </ThemeProvider>
      </div>
      {withErrors && <p className={'error-message'}>{error && error}</p>}
    </>
  );
}