import React , { useEffect } from 'react'
import Select from '../../../../components/lib/Select/CustomSelect';
import Loader from '../../../../components/lib/Loader/Loader'
import DocRow from '../Documents/DocRow/DocRow'

import styles from './Documents.module.scss'

export default function Documents( { id , name , isPreview , setActiveLink , options , data , isLoading , setIsLoading,  handleChange }) {

  useEffect(() => {
    setIsLoading(false);
    // eslint-disable-next-line
  }, [data?.length]);

  return (
    <section
      id={id}
      className={styles.section}
      onMouseOver={() => id ? setActiveLink(id) : null }>
      <h2>{name}</h2>
      {!isPreview &&
        <div className={styles.select}>
          <Select
            defaultValue={null}
            placeholder={"Select documents from the active library"}
            isSearchable
            handleChange={(opt) => handleChange(opt.value)}
            options={options.filter(opt => data?.find(d => d._id === opt.value) ? false : true)}
            />
        </div>
      }
      
      {data?.map((d , i) => 
        <DocRow key={'doc' + d._id } index={i} doc={d} isPreview={isPreview} handleChange={handleChange} />
      )}
      {isLoading && <Loader />}
    </section>
  )
}
