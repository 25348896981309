import {
  ERROR_READING_GUIDES,
  MESSAGE_READING_GUIDES,
  GET_READING_GUIDES_FILTERED,
  POST_READING_GUIDE,
  PUT_READING_GUIDE,
  GET_READING_GUIDE,
  DELETE_READING_GUIDE,
} from "../actions/types";

const initialState = {
  error: null,
  readingGuidesList: [],
  readingGuide: null,
  count: 0,
  message: null
};

export default function readingGuidesReducer(state = initialState, action) {

  switch (action.type) {
    case DELETE_READING_GUIDE: 
    return {...state , readingGuidesList: state.readingGuidesList.filter(r => r._id !== action.payload ), count: state.count - 1 }
    case GET_READING_GUIDE:
      return {...state , readingGuide: action.payload }
    case PUT_READING_GUIDE:
      return {...state , readingGuide: action.payload }
    case POST_READING_GUIDE:
      return {...state , readingGuide: action.payload }
    case GET_READING_GUIDES_FILTERED:
      return {...state , readingGuidesList: action.payload.readingGuides , count: action.payload.count }
    case MESSAGE_READING_GUIDES:
      return {...state , message: action.payload }
    case ERROR_READING_GUIDES:
      return {...state , error: action.payload }
    default:
      return {...state};
  }
}