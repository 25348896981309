import React , { useEffect } from 'react';
import { MdClose } from 'react-icons/md';

import styles from './Modale.module.scss';

export default function Modale( { isOpen , modaleToggle , className , hideClose , children } ) {
  useEffect(() => {
    if (isOpen) {
      document.getElementById("filters").style.opacity = 0
      document.getElementById("filters").style.pointerEvents = 'none'
      document.getElementById("nav").style.zIndex = -1
    } else {
      document.getElementById("filters").style.opacity = 1
      document.getElementById("filters").style.pointerEvents = 'all'
      document.getElementById("nav").style.zIndex = 1
    }
  }, [isOpen])
  return (
    <div className={`${styles['modale-container']} ${isOpen? styles['is-active'] : ""}`} >
      <div className={`${className ? className :''} ${styles['modale']} ${isOpen? styles['is-active'] : ""}`}>
        {!hideClose && <button
            onClick={() => modaleToggle(false)}
            className={styles['close']}>
            <MdClose size={20} color={'#656565'} /> 
          </button>
        }
        {children}
      </div>
    </div>
  )
}