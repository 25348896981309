import React from 'react';
import { MdClose } from 'react-icons/md';
import { IoLocationSharp } from 'react-icons/io5';
import { BiWater } from 'react-icons/bi';
import { HiOutlineOfficeBuilding } from 'react-icons/hi';
import { ImBook} from 'react-icons/im';

import styles from './AuthorPanel.module.scss';

import { useGlobalContext } from '../../../context/GlobalContext';
import { useEffect } from 'react';
import { useState } from 'react';

const PublicationsRates = ({ publications }) => {
  return (
    <div className={styles.rates}>
      <label>Publication quantity</label>
      <p>Score : <span>{publications.count}</span></p>
      <div className={styles.list}>
        {publications.list.sort((a, b) => b.label - a.label).map((p) => <div
          key={p.label}
          className={styles.rate}
        >
          <div
            className={styles.bar}
            style={{ width: `${p.count * 100 / publications.max}%`  }}

          />
          <div className={`${styles.tooltip}`}>
            <p>
              <span>{p.label}</span><br/>
              {p.count} publication{p.count > 1 && 's'}
            </p>
          </div>
        </div>
        )}
      </div>
    </div>
  )
}

export default function AuthorPanel({ author, query }) {
  const dispatch = useGlobalContext()[1];
  const [ filtersCount, setFiltersCount ] = useState();
  const keywords = getObjectEntries(author?.keywords);
  const authors = getObjectEntries(author?.coAuthors);
  const publications = getPublicationsRates(author?.publications);

  function getObjectEntries(obj = {}) {
    const array = [];
    for (const key in obj) {
      array.push({ label: key, count: obj[key]});
    }
    return array;
  }

  function getPublicationsRates(obj = {}) {
    const list = [];
    let count = 0;
    let max = 0;
    for (const key in obj) {
      count += obj[key];
      if (obj[key] > max) {
        max = obj[key];
      }
      list.push({ label: key, count: obj[key]});
    }
    return {
      list,
      count,
      max,
    } 
  }

  useEffect(() => {
    let selected = [];
    for (const key in query) {
      if (query[key]) {
        if (key === "countries" || key === "cities" || key === "keywords" || key === "authors") {
          query[key].forEach((k) => {
            selected.push(k);
          });
        };
      }
    }
    setFiltersCount(selected.length);

  }, [query]);

  return (
    <div
      className={`${styles.container} ${author ? styles.active : ''}`}
    >
      <button
        className={styles.close}
        onClick={() => dispatch({ type: 'GET_AUTHOR', payload: null })}
      >
        <MdClose/>
      </button>
      <label>ID CARD</label>
      <h2>{author?.name}</h2>
      {author?.countries?.filter((c) => c?.city?.city).map((c) => 
        <div key={c?.city?.city} className={styles.icon}>
          <IoLocationSharp/> <p>{c.city.city} - {c.city.country}</p>
        </div>
      )}
      {author?.centers?.map((c) => 
        <div key={c} className={styles.icon}>
          <HiOutlineOfficeBuilding /> <p>{c}</p>
        </div>
      )}
      <div className={styles.infos}>
        {filtersCount > 0 &&
          <div className={styles.filters}>
            Activity based on <span>{filtersCount}</span> active filter{filtersCount > 1 && 's'}
          </div>
        }
        {author?.nbInteractions > 0 &&
          <div className={styles.icon}>
            <BiWater size={26}/> <p><span>{author.nbInteractions}</span> Interaction{author.nbInteractions > 1 && 's'}</p>
          </div>
        }
        {author?.nbPublications > 0 &&
          <div className={styles.icon}>
            <ImBook size={18} /> <p><span>{author.nbPublications}</span> Publication{author.nbPublications > 1 && 's'}</p>
          </div>
        }
        {publications?.list?.length > 0 &&
          <PublicationsRates
            publications={publications}
          />
        }
        {author?.trials.length > 0 &&
          <>
            <label>Trials In author center</label>
          </>
        }
        {author?.trialTopics.length > 0 &&
          <>
            <div className={styles.label}>
              <div className={`${styles.puce}`}>
              </div>
              <label>MAIN TRIALS TOPIC</label>
            </div>
          </>
        }
        {keywords.length > 0 &&
          <>
            <div className={styles.label}>
              <div className={`${styles.puce} ${styles.primary}`}>
              </div>
              <label>FAVORITE THEME</label>
            </div>
            {keywords?.map((k) => <p key={k.label}>{k.count} - {k.label}</p>)}
          </>
        }
        {authors.length > 0 &&
          <>
            <div className={styles.label}>
              <div className={`${styles.puce}`}>
              </div>
              <label>CO-Authors</label>
            </div>
            {authors?.map((k) => <p key={k.label}>{k.count} - {k.label}</p>)}
          </>
        }

      </div>
    </div>
  )
}
