import React from 'react';
import { Link } from 'react-router-dom/cjs/react-router-dom';
// import { getData } from '../../actions';
// import { ERROR_AUTH } from '../../actions/types';
import { useHistory } from "react-router-dom";

import { useGlobalContext } from '../../context/GlobalContext';

import styles from './Auth.module.scss';
import { API_URL } from '../../actions';

export default function IndexPage() {

  // const history = useHistory();

  const context = useGlobalContext()[0];

  return (
    <div className={styles.login}>

      <div className={styles['col-img']}></div>

      <div className={styles['col-form']}>

        <p className={`${styles.title} bold`}>
          <span className={`${styles.name} bold`} >BIBLIOCTA</span><br/>
          <span className="regular">BY </span><span>octapharma</span>
        </p>

        <div className={styles.sso}>
        <a
          className={`${styles.btn} ${styles.primary}`}
          type="button"
          href={API_URL + '/auth/login-saml' }
        >
          Log through SSO
        </a>
        <Link to="/login">
          <button
            className={`${styles.btn} ${styles.primary}`}
            type="button"
          >
            Log with password
          </button>
        </Link>
        </div>
        {context.auth.errorLogin === "You are not authorized to consult BIBLIOCTA" &&
          <p className="text-error text-center">{context.auth.errorLogin}</p>
        }
      </div>

    </div>
  )
}
