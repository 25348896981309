import { postData } from './index';

import {
  ERROR_DATAVIZ,
  GET_LOCATIONS,
  GET_DASHBOARD,
  GET_DATAVISION,
  GET_TIMELINE,
} from "./types"


export async function getLocationsFilteredAction(dispatch , data){
  let url = "/dataviz/bibliocta/location";
  var locations;

  let obj = {...data}

  delete obj.number;
  delete obj.page;
  delete obj.sortBy;

  await postData(GET_LOCATIONS , ERROR_DATAVIZ , url, dispatch , obj, true ).then((response)=>{
    locations = response
  });

  dispatch({
    type : GET_LOCATIONS,
    payload : locations
  });

};

export async function getDashboardFilteredAction(dispatch , data ){
  let url = "/dataviz/bibliocta/dashboard";
  var dashboard;

  let obj = {...data}

  delete obj.number;
  delete obj.page;
  delete obj.sortBy;

  await postData(GET_DASHBOARD , ERROR_DATAVIZ , url, dispatch , obj, true ).then((response)=>{
    dashboard = response
  });

  dispatch({
    type : GET_DASHBOARD,
    payload : dashboard
  });

};

export async function getDatavisionFilteredAction(dispatch , data){
  let url = "/dataviz/bibliocta/node";
  var datavision;

  let obj = {...data}

  delete obj.number;
  delete obj.page;
  delete obj.sortBy;

  await postData(GET_DATAVISION , ERROR_DATAVIZ , url, dispatch , obj , true ).then((response)=>{
    datavision = response
  });

  dispatch({
    type : GET_DATAVISION,
    payload : datavision
  });

};

export async function getTimelineFilteredAction(dispatch , data){
  let url = "/dataviz/bibliocta/trial";
  var trials;

  let obj = {...data}

  if (obj?.authors?.length) {
    obj.authors = obj.authors.map(a => {
      if (a?.foreName) return a;
      let author = a.split('/');
      return ({ foreName : author[0] , lastName : author[1]})
    })
  }

  delete obj.number;
  delete obj.page;
  delete obj.sortBy;

  await postData(GET_TIMELINE , ERROR_DATAVIZ , url, dispatch , obj , true ).then((response)=>{
    trials = response.trials
  });

  dispatch({
    type : GET_TIMELINE,
    payload : trials
  });

};