import React, { useState } from 'react';
import Select from 'react-select';
import { primaryStyle , secondaryStyle } from './styles';

import styles from './CustomSelect.module.scss';

export default function CustomSelect({ defaultValue , label , options , isMulti, isClearable , isSearchable , secondary, withErrors , error , handleChange , placeholder }) {

  const [ selected , setSelected ] = useState(defaultValue);

  function onChange(val) {
    setSelected(val);
    handleChange(val);
  }

  return (
    <div className={styles.select}>
      {label && <label>{label}</label>}
      <Select
        styles={secondary ? secondaryStyle : primaryStyle}
        options={options}
        value={selected}
        noOptionsMessage={() => "no options"}
        isSearchable={isSearchable ? true : false }
        isClearable={isClearable ? true : false }
        isMulti={isMulti ? true : false }
        placeholder={placeholder ? placeholder : "Séléctionner"}
        onChange={(val) => onChange(val)}
        />
      {withErrors && <p className={styles['error-message']}>{error && error}</p>}
    </div>
  )
}
