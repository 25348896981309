import React , { useEffect , useState ,useRef } from 'react';
import * as d3 from 'd3';
import geojson from './world-map-110.json'

import Tooltip from '../../../components/partials/Tooltip/Tooltip';

import styles from './Map.module.scss';

export default function Map({ data , mapWidth , mapHeight , mapId , enableZoom , handleChangeCity , isDashboard }) {

  const mapRef = useRef(null);
  const [ activeCountry , setActiveCountry ] = useState();

  const [ tooltipData , setTooltipData ] = useState({ x: mapWidth / 2 , y: mapHeight / 2 });

  useEffect(() => {
    if (data && mapHeight && mapWidth) {
      drawMap();
      window.addEventListener('resize', drawMap() );
      return () => {
        window.removeEventListener('resize', drawMap() );
      }
    }
    // eslint-disable-next-line
  }, [data , mapHeight , mapWidth]);

  function drawMap() {
    let geojsonData = {...geojson}
    let selectedCountry = null
    let maxCountrySize = 0;
    let maxCitySize = 0
    let height = mapHeight ;
    let width = mapWidth;

    geojsonData.features.forEach(el => {
      let found = data?.countries.find(d => d.iso3 === el.properties.iso_a3)
      if (found) {
        if (found.size > maxCountrySize ) maxCountrySize = found.size
        el.properties.size = found.size
      }
    });

    data.cities.forEach(el => {
      if (el.size > maxCitySize ) maxCitySize = el.size
    });

    let cityRadiusRange = d3.scaleLinear()
    .domain([ 1 , maxCitySize ])
    .range([ 3 , 20 ]);

    let opacityRange = d3.scaleLinear()
    .domain([ 0 , maxCountrySize ])
    .range([ 0.1 , 1 ]);


    if (d3.select(`#svg-${mapId}`)) {
      d3.select(`#svg-${mapId}`).remove();
    }

    let center = d3.geoCentroid(geojsonData);
    let scale = isDashboard ? 95 : 150
    let offset = isDashboard ? [ (width/2) + 40  , height/2 ] : [ (width/2) + 70 , ( height/2 - 50 ) ];

    let projection = d3.geoMercator()
      .scale(scale)
      .center(center)
      .translate(offset);

    let geoGenerator = d3.geoPath()
      .projection(projection)

    let zoom = d3.zoom()
      .scaleExtent([1, scale])
      .on("zoom", zoomed);

    let svg = d3.select(`#${mapId}`)
      .append('svg')
      .attr('id' , `svg-${mapId}` )
      .attr('width' , width + 'px' )
      .attr('height' , height + 'px')


    let g = svg.append("g")
      .attr("id", "area")

      g.selectAll("path")
      .data(geojsonData.features)
      .enter().append("path")
      .attr("d", d => geoGenerator(d))
      .attr("fill" , (d) => {
        let found = data?.countries?.find(c => c.iso3 === d.properties.iso_a3)
        if (found?.size) {
          return '#20B9BC'
        } else {
          return "#E3E3E3"
        }
      })
      .style("opacity" , (d) => {
        let found = data?.countries?.find(c => c.iso3 === d.properties.iso_a3)
        if (found?.size) {
          return opacityRange(found.size)
        } else {
          return 1
        }
      })
      .on("click", enableZoom ? (e, d ) => clicked(e , d) : null);
    
      svg.call(zoom);

      let cities = g.selectAll('circle')
        .data(data.cities)
        .enter()
        .append('circle')
        .attr("r", (d) => d.size )
        .attr('cx', d => projection([d.lng, d.lat])[0])
        .attr('cy', d => projection([d.lng, d.lat])[1])
        .style('pointer-events' , 'none')
        .style('opacity' , 0 )
        .attr("fill" , (d) => {
          return '#192243'
        })
        .on('mouseover' , (e , d) => {
          return setTooltipData({...d , color: '#192243' , x: e.x - 70 , y: e.y - 140 });
        })
        .on('mouseout' , (e , d) => {
          return setTooltipData({ x: e.x - 70 , y: e.y - 140 });
        })
        .on("click", enableZoom ? (e , d ) => handleChangeCity(d.city) : null);


      if (activeCountry) {
        let bounds = geoGenerator.bounds(activeCountry),
        dx = bounds[1][0] - bounds[0][0],
        dy = bounds[1][1] - bounds[0][1],
        x = (bounds[0][0] + bounds[1][0]) / 2,
        y = (bounds[0][1] + bounds[1][1]) / 2,
        scale = Math.max(1, Math.min(8, 0.9 / Math.max(dx / width, dy / height))) * 0.8,
        translate = [width / 2 - scale * x, height / 2 - scale * y];
        cities
          .attr("r", 0 )
          .style('opacity' , 0)
          .transition()
          .delay(50)
          .duration(250)
          .attr("r", (d) => cityRadiusRange(d.size) / scale )
          .style('pointer-events' , c => {
            if (c.country === activeCountry?.properties.iso_a3 ) return "all"
            return "none"
          })
          .style('opacity' , c => {
            if (c.country === activeCountry?.properties.iso_a3 ) return 1
            return 0
          })

        svg
          .call( zoom.transform, d3.zoomIdentity.translate(translate[0],translate[1]).scale(scale) );
      }

      function zoomed(e) {
        if (enableZoom) {
          g.style("stroke-width", 0.25 / e.transform.k + "px");
          g.attr("transform", e.transform);

          cities
          .attr("r", (c) => {
            return cityRadiusRange(c.size) / e.transform.k
          })
        }
      }

      function clicked(e , d  ) {
        e.stopPropagation();
        if (selectedCountry?.properties.iso_a3 === d.properties.iso_a3) {
          setActiveCountry();
          selectedCountry = null
          cities
            .transition()
            .duration(750)
            .attr("r", 0 )
            .style('pointer-events' , 'none')
            .style('opacity' , 0)

          svg.transition().duration(750).call(
            zoom.transform,
            d3.zoomIdentity,
            d3.zoomTransform(svg.node()).invert([width / 2, height / 2])
          );
        } else {
          setActiveCountry(d);
          selectedCountry = d
          let bounds = geoGenerator.bounds(d),
            dx = bounds[1][0] - bounds[0][0],
            dy = bounds[1][1] - bounds[0][1],
            x = (bounds[0][0] + bounds[1][0]) / 2,
            y = (bounds[0][1] + bounds[1][1]) / 2,
            scale = Math.max(1, Math.min(8, 0.9 / Math.max(dx / width, dy / height))) * 0.8,
            translate = [width / 2 - scale * x, height / 2 - scale * y];

          svg.transition()
            .duration(750)
            .call( zoom.transform, d3.zoomIdentity.translate(translate[0],translate[1]).scale(scale) );

          cities
            .transition()
            .duration(750)
            .attr("r", (c) => {
              if (c.country === d?.properties.iso_a3 ) return cityRadiusRange(c.size) / scale
              return 0
            })
            .style('pointer-events' , c => {
              if (c.country === d?.properties.iso_a3 ) return "all"
              return "none"
            })
            .style('opacity' , c => {
              if (c.country === d?.properties.iso_a3 ) return 1
              return 0
            })
        }

      }

  }


  return (
    <div ref={mapRef} className={styles.map} id={mapId} style={{ height: mapHeight , width: mapWidth }}>
      <Tooltip data={tooltipData} type={"geography"}/>
    </div>
  )
}
