export const  //AUTH ACTIONS
              IS_AUTH =  'IS_AUTH',
              ERROR_AUTH = 'ERROR_AUTH',
              ERROR_LOGIN = 'ERROR_LOGIN',
              USER_AUTH = 'USER_AUTH',
              FORGOT_PASSWORD = 'FORGOT_PASSWORD',
              RESET_PASSWORD ='RESET_PASSWORD',
              GET_LIST= 'GET_LIST',
              LOGIN = 'LOGIN',
              PUT_USER_AUTH = 'PUT_USER_AUTH',
              MESSAGE_AUTH = 'MESSAGE_AUTH',
              SET_TOAST = 'SET_TOAST',
              //RECORDS ACTIONS
              MESSAGE_RECORDS = 'MESSAGE_RECORDS',
              ERROR_RECORDS = 'ERROR_RECORDS',
              SET_QUERY = 'SET_QUERY',
              LIBRARY_IS_LOADING = 'LIBRARY_IS_LOADING',
              SET_LIBRARY_VIEW = 'SET_LIBRARY_VIEW',
              GET_RECORDS = 'GET_RECORDS',
              GET_TOPICS = 'GET_TOPICS',
              RESET_RECORDS ='RESET_RECORDS',
              POST_RECORD = 'POST_RECORD',
              GET_RECORD = 'GET_RECORD',
              PUT_RECORD = 'PUT_RECORD',
              DELETE_RECORD = 'DELETE_RECORD',
              GET_OPTIONS_LISTS = 'GET_OPTIONS_LISTS',
              GET_DATES = 'GET_DATES',
              POST_FILE = 'POST_FILE',
              GET_FILE_PREVIEW  = 'GET_FILE_PREVIEW',
              DELETE_FILE = 'DELETE_FILE',
              //DATAVIZ ACTIONS
              ERROR_DATAVIZ = 'ERROR_DATAVIZ',
              GET_LOCATIONS = 'GET_LOCATIONS',
              GET_DASHBOARD = 'GET_DASHBOARD',
              GET_DATAVISION = 'GET_DATAVISION',
              GET_TIMELINE = 'GET_TIMELINE',
              DATAVIZ_IS_LOADING = 'DATAVIZ_IS_LOADING',
              //READIN GUIDES ACTIONS
              ERROR_READING_GUIDES = 'ERROR_READING_GUIDES',
              MESSAGE_READING_GUIDES = 'MESSAGE_READING_GUIDES',
              GET_READING_GUIDES_FILTERED = 'GET_READING_GUIDES_FILTERED',
              POST_READING_GUIDE = 'POST_READING_GUIDE',
              PUT_READING_GUIDE = 'PUT_READING_GUIDE',
              GET_READING_GUIDE ='GET_READING_GUIDE',
              DELETE_READING_GUIDE ='DELETE_READING_GUIDE',
              // AUTHORS ACTIONS
              GET_AUTHORS = 'GET_AUTHORS',
              GET_AUTHOR = 'GET_AUTHOR';