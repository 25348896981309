import React , { useState , useRef } from 'react'
import { Document, Page , pdfjs } from 'react-pdf';
import { RiArrowLeftSLine , RiArrowRightSLine } from "react-icons/ri";

import styles from './PdfReader.module.scss';
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

export default function PdfReader({ file }) {

  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);

  const containerRef = useRef();

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
  }

  function renderPageNav() {
    return (
      <div className={styles.nav}>
        <button
          onClick={() => pageNumber !== 1 ? setPageNumber(pageNumber - 1) : null }
          >
          <RiArrowLeftSLine size={26} />
        </button>
          <p>{pageNumber}/{numPages}</p>
        <button
          onClick={() => pageNumber !== numPages ? setPageNumber(pageNumber + 1) : null }
          >
          <RiArrowRightSLine size={26} />
        </button>
      </div>
    )
  }

  return (
    <div
      ref={containerRef}
      className={styles.container}>
      {/* {renderPageNav()} */}
       <Document
          className={styles.reader}
          file={file}
          onLoadSuccess={onDocumentLoadSuccess}
        >
          <Page
            className={styles.page}
            width={containerRef?.current?.clientWidth}
            pageNumber={pageNumber} />
        </Document>
       {renderPageNav()}
    </div>
  )
}
