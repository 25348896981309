import {
  ERROR_RECORDS,
  MESSAGE_RECORDS,
  LIBRARY_IS_LOADING,
  GET_RECORDS,
  SET_QUERY,
  GET_TOPICS,
  RESET_RECORDS,
  GET_OPTIONS_LISTS,
  SET_LIBRARY_VIEW,
  POST_RECORD,
  PUT_RECORD,
  DELETE_RECORD ,
  GET_RECORD,
  GET_DATES,
  GET_FILE_PREVIEW,
  POST_FILE,
  DELETE_FILE,
  GET_AUTHORS,
  GET_AUTHOR
} from "../actions/types";

let today = new Date(new Date().setMonth( new Date().getMonth() + 1 ));
let startDate = new Date(new Date().setFullYear(new Date().getFullYear() - 1 ));

const initialState = {
  error: null,
  message: null,
  topics: null,
  recordsList: [],
  count: 0,
  authorsList: [],
  author: null,
  authorsCount: 0,
  optionsLists: null,
  dates: null,
  internalRecord: null,
  file: null,
  filePreview: null,
  libraryIsLoading: false,
  libraryView : "documents",
  query: {
    search:null,
    type:["publication"],
    topic:[],
    keywords: [],
    countries:[],
    authors:[],
    filterType: "and",
    cities:[],
    sortBy:"publicationDate",
    start: new Date(startDate).toISOString() ,
    end: new Date(today).toISOString() ,
    page:1,
    number: 20
  }
};

export default function recordsReducer(state = initialState, action) {

  switch (action.type) {

    case SET_LIBRARY_VIEW:
      return {...state , libraryView: action.payload }

    case LIBRARY_IS_LOADING:
      return {...state , libraryIsLoading: action.payload }

    case GET_FILE_PREVIEW:
        return {...state , filePreview: action.payload }

    case POST_FILE:
      return {...state , file: action.payload }
    
    case DELETE_FILE:
      return {...state , file: action.payload.file , message: action.payload.message }

    case GET_DATES:
      return {...state , dates: action.payload }

    case SET_QUERY:
      return {...state , query: action.payload }

    case DELETE_RECORD: 
      return {...state , recordsList: state.recordsList.filter(r => r._id !== action.payload ), count: state.count - 1 }

    case GET_RECORD:
        return {...state , internalRecord: action.payload }

    case PUT_RECORD:
      let updatedList = [...state.recordsList];
      let index = updatedList.findIndex( r => r._id === action.payload._id )
      if (index !== -1) {
        updatedList[index] = action.payload
      }
      return {...state , internalRecord: action.payload , recordsList: updatedList }

    case POST_RECORD:
      let newList = [...state.recordsList];
      newList.unshift(action.payload)
      return {...state , recordsList: newList, count: state.count + 1 }

    case RESET_RECORDS:
      return {...state , recordsList: [] , count: 0 }

    case GET_RECORDS:
      let records = [];
      let query = action.payload.query;
      if (query.page ===  1) {
        records = action.payload.records?.map((d , i) => ({...d , index: i }))
      } else {
        records = [...state.recordsList , ...action.payload.records.map((d , i) => ({...d , index: i }))]
      }
      return {...state , recordsList: records , count: action.payload.count }

    case GET_AUTHORS:
      let authors = [];
      let authorsQuery = action.payload.query;
      if (authorsQuery.page ===  1) {
        authors = action.payload.authors?.map((d , i) => ({...d , index: i }))
      } else {
        authors = [...state.authorsList , ...action.payload.authors.map((d , i) => ({...d , index: i }))]
      }
      return {...state , authorsList: authors , authorsCount: action.payload.count }

    case GET_AUTHOR:
      return {...state , author: action.payload}

    case ERROR_RECORDS:
      return {...state , error: action.payload}

    case GET_TOPICS:
      return {...state , topics: action.payload}

    case GET_OPTIONS_LISTS:
      return {...state , optionsLists: action.payload}

    case MESSAGE_RECORDS:
        return {...state , message: action.payload}

    default:
      return {...state};
  }
}