import React, { useState , useEffect , useRef } from 'react';
import { BiMinus } from "react-icons/bi";
import { BsPlus , BsTag } from "react-icons/bs";
import { IoLocationOutline } from "react-icons/io5";
import Switch from "react-switch";
import { BiUser } from "react-icons/bi";
import { MdRadioButtonChecked , MdRadioButtonUnchecked } from "react-icons/md";

export default function FilterTags({ name , filterIsOpen , styles , list , selected , setSelected, handleChangeFilterType , filterType }) {

  const [ listIsOpen , setListIsOpen  ] = useState(false);
  const [ search , setSearch ] = useState("");
  const listRef = useRef();
  const selectedRef = useRef();

  useEffect(() => {
    if (!filterIsOpen) setListIsOpen(false)
  }, [filterIsOpen]);

  function handleChange(val) {
    let newList = [...selected];
    if (selected.find(d => d.label === val.label )) {
      newList = selected.filter(item => item.label !== val.label);
    } else {
      newList.push(val)
    }
    setSearch("")
    return setSelected(newList);
  }

  function renderIcon(value) {
    if (value.value.type === "authors") return <BiUser size={18} color={"#FFFFFF"}/>
    if (value.value.type === "countries" || value.value.type === "cities") return <IoLocationOutline size={18} color={"#FFFFFF"}/>
    return <BsTag size={18} color={"#FFFFFF"}/>
  }

  return (
    <>
      <p>{name} {selected.length > 0 && <span>({selected.length})</span>} </p>
      <button
        onClick={() => setListIsOpen(!listIsOpen)}
        >
        {listIsOpen ? <BiMinus size={20} color={"#FFFFFF"}/> : <BsPlus size={20} color={"#FFFFFF"}/>}
      </button>
        <div className={styles.search}>
          {listIsOpen &&
            <>
            <input
              value={search}
              onChange={(e) => setSearch(e.target.value)}
              />
          </>
          }
          <div className={`${styles.list} ${styles['selected-list']}`} style={{ height : listIsOpen ? selectedRef.current.clientHeight + 70 : 0 }}>
            <div ref={selectedRef}>
              <div className={styles.toggle}>
                <Switch
                  onChange={() => handleChangeFilterType()}
                  height={16}
                  width={32}
                  handleDiameter={18}
                  activeBoxShadow={'0 0 2px 3px #FFFFF'}
                  offColor={'#22A9AF'}
                  onColor={'#22A9AF'}
                  uncheckedIcon={false}
                  checkedIcon={false}
                  checked={filterType === "or"} />
                <p>{filterType === "or" ? "Cumul" : "Restrict"}</p>
              </div>
              {selected?.map((item , i) => {
                let isActive = selected?.find(d => d.label === item.label );
                return (
                  <div key={item.label + i}
                    onClick={() => handleChange(item)}
                    className={`${styles.item} ${styles.isActive}`}>
                    <div className={styles.option}>
                      {isActive ? <MdRadioButtonChecked size={14} color={"#FFFFFF"}/> : <MdRadioButtonUnchecked size={14} color={"#FFFFFF"}/> }
                      <div className={styles.icon}>{renderIcon(item)}</div>
                      <p>{item.label}</p> 
                    </div>
                  </div>
              )})}
            </div>
          </div>
        </div>
        <div className={`${styles.list}`} style={{ maxHeight: 300, top: listIsOpen ? selected.length > 0 ? selectedRef.current.clientHeight + 90 : 105 : 30 , height : listIsOpen ? listRef.current?.clientHeight + 60 : 0 }}>
          <div ref={listRef} className={styles['list-content']}>
            {list?.filter(d => d.label?.toLowerCase().includes(search.toLowerCase())).filter((d ,i) => i < 100 ).map((item , i) => {
              let isActive = selected?.find(d => d.label === item.label );
              if (isActive) return null;
              return (
                <div key={item.label + i}
                  onClick={() => handleChange(item)}
                  className={`${styles.item}`}>
                  <div className={styles.option}>
                    {isActive ? <MdRadioButtonChecked size={14} color={"#FFFFFF"}/> : <MdRadioButtonUnchecked size={14} color={"#FFFFFF"}/> }
                    <div className={styles.icon}>{renderIcon(item)}</div>
                    <p>{item.label}</p> 
                  </div>
                </div>
            )})}
            {list?.filter(d => d.label?.toLowerCase().includes(search.toLowerCase())).length - list?.filter(d => d.label?.toLowerCase().includes(search.toLowerCase())).filter((d ,i) => i < 100 ).length !== 0 && <p className={styles["tags-count"]}>{list?.filter(d => d.label?.toLowerCase().includes(search.toLowerCase())).length - list?.filter(d => d.label?.toLowerCase().includes(search.toLowerCase())).filter((d ,i) => i < 100 ).length } others tags</p>}
          </div>
        </div>
    </>
  )
}
