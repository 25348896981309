import React , { useState } from 'react';

//Components
import Creatable from '../../lib/Select/Creatable';


//style
import styles from './TagsSelect.module.scss';

export default function TagsSelect({ onChange , label , tagsOptions , defaultValue , withErrors , isReadOnly , error  }) {
  
  const [ selectedList , setSelectedList ] = useState(defaultValue ? defaultValue : []);

  // const getLists = useCallback(() => { getQuestionListsAction(dispatch) },[dispatch]);

  // useEffect(() => {
  //   getLists();
  // },[getLists])

  // const tagsOptions = context.questions?.lists?.tags?.filter(t => t ? true : false ).map(opt => ({ value: opt , label: opt })) 

  function handleChange(list) {
    setSelectedList(list);
    onChange(list.map(opt => opt.value));
  }

  return (
    <>
      <label>{label}</label>
      <div className={styles.container}>
        <Creatable
          isMulti
          defaultValue={selectedList}
          formatCreateLabel={(val) => `Add the keyword "${val}"`}
          handleChange={(val) => handleChange(val)}
          options={tagsOptions ? tagsOptions  : []}
          />
        {/* <div className={styles.list}>
          {selectedList && selectedList.map((tag, i) =>
            <div
              key={`tag ${i}`}
              className={`${styles.tag}`}
              >
              <p>{tag.value}</p>
              {!isReadOnly &&
                <button
                  type="button"
                  onClick={() => handleChange(selectedList.filter(item => item.value !== tag.value))}
                  className={styles.close}
                  >
                  <MdClose size={18}/>
                </button>
              }
            </div>
          )}
        </div> */}
        {withErrors && <p className={styles['error-message']}>{error && error}</p>}
      </div>
    </>
  )
}
