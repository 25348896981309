import { postData , putData , getData , deleteData } from './index';

import {
  ERROR_READING_GUIDES,
  GET_READING_GUIDES_FILTERED,
  POST_READING_GUIDE,
  PUT_READING_GUIDE,
  GET_READING_GUIDE,
  DELETE_READING_GUIDE
} from "./types"

export async function getReadingGuidesFilteredAction(dispatch , obj){
  let url = "/readingguide/filtered";
  var readingGuides;

  let data = {...obj}



  delete data.authors;
  delete data.cities;
  delete data.countries;
  delete data.keywords;
  delete data.type;

  await postData(GET_READING_GUIDES_FILTERED , ERROR_READING_GUIDES , url, dispatch , data , true ).then((response)=>{
    readingGuides = response
  });

  obj.number = 20;

  dispatch({
    type : GET_READING_GUIDES_FILTERED,
    payload : {
      readingGuides: readingGuides.readingGuides,
      count: readingGuides.count ,
      query: obj
    }
  });
};

export async function postReadingGuideAction(dispatch , obj ){
  let url = "/readingguide";
  var readingGuide;

  await postData(POST_READING_GUIDE , ERROR_READING_GUIDES , url, dispatch , obj, true ).then((response)=>{
    readingGuide = response.readingGuide
  });

  dispatch({
    type :  'SET_TOAST',
    payload : {
      type: "success",
      message: "Reading guide saved"
    }
  });

  dispatch({
    type : POST_READING_GUIDE,
    payload : readingGuide
  });
  
};

export async function putReadingGuideAction(dispatch , id , data ) {
  let url = "/readingguide/" + id;
  var putReadingGuide;

  await putData(PUT_READING_GUIDE, ERROR_READING_GUIDES ,  url , dispatch, data , true ).then((response)=>{
    putReadingGuide = response.readingGuide
  });

  dispatch({
    type : PUT_READING_GUIDE,
    payload : putReadingGuide
  });

  dispatch({
    type :  'SET_TOAST',
    payload : {
      type: "success",
      message: "The reading guide has been updated"
    }
  });
}

export async function getReadingGuideAction(dispatch , id ) {
  let url = "/readingguide/" + id;
  var getReadingGuide;

  await getData(GET_READING_GUIDE, url, dispatch , true ).then((response)=>{
    getReadingGuide = response.readingGuide
  });

  dispatch({
    type: GET_READING_GUIDE,
    payload : getReadingGuide
  });

}

export async function deleteReadingGuideAction(dispatch , id ){
  let url = "/readingguide/" + id;
  let deleted

  await deleteData(ERROR_READING_GUIDES, url, dispatch  ).then((response)=>{
    deleted = response
  });

  if (deleted ) {
    dispatch({
      type :  'SET_TOAST',
      payload : {
        type: "success",
        message: "The document has been removed"
      }
    });
    dispatch({
      type :  DELETE_READING_GUIDE,
      payload : id
    });
  }

};