import React , { useState , useEffect , useCallback } from 'react';
import { useLocation , useHistory } from 'react-router-dom';
import * as QueryString from "query-string";
import { MdChevronRight } from 'react-icons/md'
import { Range } from 'rc-slider';
import { format } from 'date-fns';
import 'rc-slider/assets/index.css';
import './customRange.scss'
import { getDatesAction } from '../../../../../actions/recordsActions';
import { useGlobalContext } from '../../../../../context/GlobalContext';
import { setQueryUrl } from '../../../../../utils/utils';
import styles from './Timeline.module.scss';

export default function Timeline({ isOpen }) {

  const [ context, dispatch ] = useGlobalContext();

  const history = useHistory()
  const location = useLocation();
  const { pathname , search } = location;
  let queryFilters = QueryString.parse(search);

  const datesData = context.records?.dates
  const { query } = context.records;

  const [ values , setValues ] = useState();
  const [ marks , setMarks ] = useState();
  const [ dates , setDates ] = useState();

  const initData = useCallback(() => {
    getDatesAction(dispatch);
  },[dispatch]);

  useEffect(() => {
    initData();
  }, [initData]);

  useEffect(() => {
    if (datesData?.newest && datesData?.oldest) {
    const startDate = new Date(new Date(datesData.oldest.publicationDate).getFullYear(), 0, 1);
    const endDate = new Date(new Date(datesData.newest.publicationDate).getFullYear() + 1, 0, 1);
    let currentDate = startDate;
    const datesList = [];
    do {
      const isoDate = new Date(currentDate.getTime() - (currentDate.getTimezoneOffset() * 60000)).toISOString();
      currentDate = new Date(new Date(isoDate).getFullYear() + 1, 0, 1);
      datesList.unshift(currentDate.toISOString());
    } while (currentDate?.getTime() <= endDate?.getTime());
      setDates(datesList);
    }

  }, [datesData])


  useEffect(() => {
    if (dates) {
      let marksData = {};
      let prevYear = new Date();
      dates.forEach((d , i ) => {
        let date = new Date(d);
        if(i === 0) {
          prevYear = date.getFullYear();
          return marksData[i] = <strong>{date.getFullYear()}</strong>;
        }
        if(date.getFullYear() + 2 < prevYear) {
          prevYear = date.getFullYear()
          return marksData[i] = <strong>{date.getFullYear()}</strong>;
        }
        if(i === dates.length - 1) {
          return marksData[i] = <strong>{date.getFullYear()}</strong>;
        }
      });
      setMarks(marksData);
    }
  },[dates]);

  useEffect(() => {
    if (!dates) return;
    let startIndex = dates.indexOf(query.start);
    let endIndex = dates.indexOf(query.end);
    let start = new Date(query.start);
    let end = new Date(query.end);
    dates.forEach((d , i ) => {
      let date = new Date(d)
      if (date.getFullYear() === start.getFullYear() && date.getMonth() === start.getMonth()) {
        startIndex = i
      }
      if (date.getFullYear() === end.getFullYear() && date.getMonth() === end.getMonth()) {
        endIndex = i
      } 
    })
    if (endIndex !== -1 && startIndex !== -1) {
      setValues([endIndex , startIndex ])
    } else {
      setValues([0 , 1])
    }

  },[dates , query.start , query.end])

  function handleChangeDates(datesArray) {
    let start, end;
    [end, start] = datesArray;
    let data = query;
    data.authors = queryFilters?.authors ? queryFilters?.authors?.split(',') : [];
    data.start = dates[start]
    data.end = dates[end]
    data.page = 1
    data.number = 20
    dispatch({
      type: 'RESET_RECORDS'
    })
    dispatch({
      type: 'LIBRARY_IS_LOADING',
      payload: true
    })
    // history.replace({ search: setQueryUrl(data)});

    history.push(pathname + setQueryUrl(data) )

  }

  return (
    <div id="timeline" className={`${styles.container} ${isOpen ? styles['is-open']: ""}`}>
      {values &&
        <Range
          vertical
          className={"custom-range"}
          min={0}
          max={dates?.length}
          allowCross={false}
          marks={marks}
          value={values}
          onChange={(val) => setValues(val)}
          onAfterChange={(val) => handleChangeDates(val)}
        />
      }
      {dates && values?.length && 
        <div className={styles.dates}>
          <p>{dates[values[1]] && format(new Date(dates[values[1]]), 'MM/yyyy')}</p> 
          <MdChevronRight size={20}/>
          <p>{dates[values[0]] && format(new Date(dates[values[0]]), 'MM/yyyy')} </p>
        </div>
      }
    </div>
  )
}
