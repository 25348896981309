import React, { useCallback, useEffect } from 'react';
import Loader from '../../components/lib/Loader/Loader';
import styles from './Auth.module.scss';
import { useParams } from "react-router-dom";
import { useGlobalContext } from '../../context/GlobalContext';
import { TOOL_ID } from '../../actions';
import { getSsoUserAuthAction } from '../../actions/authActions';
import { useHistory } from 'react-router-dom/cjs/react-router-dom';

export default function SsoPage() {
  const { token } = useParams();
  const [ context , dispatch ] = useGlobalContext();

  const history = useHistory();

  const getUser = useCallback(() => {
    getSsoUserAuthAction(dispatch, token);
  },[dispatch , token]);

  useEffect(() => {
    getUser();
    // eslint-disable-next-line 
  }, [])

  useEffect(() => {
    if (context.auth.userAuth) {
      if (context.auth.userAuth?.tools.find((t) => t.tool === TOOL_ID )) {
        history.push("/topics");
      }
    }
  },[context.auth.userAuth , history]);

  useEffect(() => {
    if (context.auth.errorLogin) {
      history.push("/index");
    }
  },[context.auth.errorLogin , history]);

  return (
    <div className="page-container">
      <div className={styles['sso-loader']}>
        <Loader/>
      </div>
    </div>
  )
}