import React from 'react'
import styles from './TimelineRow.module.scss';

export default function TimelineRow({ doc , index }) {
  function getColor() {
    let color = null;
    if(doc.content.status === 'Not yet recruiting') {
      color = '#89E0E2';
    }
    if(doc.content.status === 'Not yet recruiting' || doc.content.status === 'Active, not recruiting') {
      color = '#89E0E2';
    }
    if(doc.content.status === 'Recruiting') {
      color = '#327EA9';
    }
    if(doc.content.status === 'Suspended' || doc.content.status === 'Terminated' || doc.content.status === 'Withdraw' || doc.content.status === 'Withdrawn') {
      color = '#F6525C';
    }
    if(doc.content.status === 'Completed') {
      color = '#b7d955';
    }
    if(doc.content.status === 'Unknown status') {
      color = '#d4d4d4';
    }
    if(doc.content.status === 'Enrolling by invitation') {
      color = '#DABFC6';
    }
    if(doc.content.study_results  === 'Available'
      || doc.content.study_results  === 'No longer available'
      || doc.content.study_results  === 'Temporarily not available'
      || doc.content.study_results  === 'Approved for marketing'
    ) {
      color = '#C9A4E6';
    }
    if (!color) {
      color = '#d4d4d4';
    }
    return color;
  } 
  return (
    <div className={styles.container} style={{ animationDelay: .08 * index + 's' }}>
      <a href={doc.url} target="_blank" rel="noreferrer" style={{ transform: doc.style.transform , width: doc.style.width , backgroundColor: getColor() }} >{doc.title}</a>
    </div>
  )
}
