import React , { useEffect , useState , useRef } from 'react';
import { useHistory } from 'react-router-dom';
import { useSpring, animated } from 'react-spring';
//Style
import styles from './Authors.module.scss';

//golbal context
import { setQueryUrl } from '../../utils/utils';
import { useGlobalContext } from '../../context/GlobalContext';

//Components
import DatavizLoader from '../../components/partials/DatavizLoader/DatavizLoader';
import AuthorRow from '../../components/partials/AuthorRow/AuthorRow';
import CustomSelect from '../../components/lib/Select/CustomSelect';

export default function AuthorsPage() {

  const [ context , dispatch ] = useGlobalContext();
  const history = useHistory();
  const { query , authorsCount , dates , authorsList , libraryIsLoading } = context.records;

  const containerRef = useRef(null);
  const scrollRef = useRef(null);
  const sortByOptions = [
    { value: 'publicationDate' , label: 'Sort by publications count'},
    { value: 'name' , label: 'Sort by alphabetical order' }
  ]

  const springAuthorsCount = useSpring({ number: authorsCount , from: { number: 0 } });

  const [ scrollTop , setScrollTop ] = useState(0);

  useEffect(() => {
    if (context.records.error) {
      dispatch({
        type: 'RESET_RECORDS',
      });
      if (context.records.error.status === 500 ) {
        dispatch({
          type: 'SET_TOAST',
          payload: {
            message: "une erreur du serveur interne s'est produite",
            type: "error"
          }
        });
      } 
    }
    // eslint-disable-next-line
  },[context.records.error])

  useEffect(() => {
    if (query.page === 1) {
      containerRef.current.scrollTo({top: 0, behavior: 'smooth'});
      setScrollTop(0);
    }
  },[query]);

  function handleScroll(e) {
    if (!libraryIsLoading && e.target.scrollTop > scrollTop && scrollRef?.current.getBoundingClientRect().bottom <= containerRef?.current.getBoundingClientRect().bottom ) {
      setScrollTop(e.target.scrollTop)
      let data = {...query};
      data.page = data.page + 1
      dispatch({
        type: 'LIBRARY_IS_LOADING',
        payload: true
      })
      history.push('/author/' + setQueryUrl(data) )
    }
  }

  function handleChangeSort(value) {
    let data = {...query};
    data.page = 1;
    data.sortBy = value;
    history.push('/author/' + setQueryUrl(data) )
  }

  return (
    <>
      <div id="library-page" className={`page-container ${styles['page-container']}`}>
        <DatavizLoader isLoading={libraryIsLoading || !dates} />
        <header className={styles.header}>
          <div className={styles.content}>
            <div className={styles.links}>
              <h2
                className={`${ styles.active}`}
                >
                Authors
              </h2>
            </div>

              <div className={`${styles.counter}`}>
                <p><animated.span>{springAuthorsCount.number.interpolate(x => x.toFixed(0))}</animated.span> authors</p>
                <div className={styles.sort}>
                  {sortByOptions[0] &&
                    <CustomSelect
                      isSearchable
                      options={sortByOptions}
                      defaultValue={sortByOptions[0]}
                      placeholder="Select a sort option"
                      handleChange={(opt) => handleChangeSort(opt.value)}
                  />
                }
                </div>
              </div>
      
          </div>
        </header>
        <div
          className={`${styles.container}`}
          onScroll={(e) => handleScroll(e)}
          ref={containerRef}
          >

          <div className={`content ${styles.content}`}>
            <div
            ref={scrollRef}
            >
            {authorsList?.map((a) =>  ({
              ...a,
              isTag: query?.authors?.find((d) => d === a.author) ? true : false,
              position: query?.authors?.find((d) => d === a.author) ? 1 : -1,
            }))
            .sort((a,b) => b.position - a.position)
            .map((a , i ) => {
              return <AuthorRow key={"author" + a.author + i} isTag={a.isTag} index={i} author={a.author} count={a.itemCount} />
            })}
            </div>
          </div>

        </div>
      </div>
    </>
  )
}