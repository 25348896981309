import React from 'react';
import styles from './MobilePage.module.scss';
import logo from '../../assets/images/logo.svg';

export default function MobilePage() {
  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <div className={styles.logo}>
          <img src={logo} alt="logo-datavision"/>
        </div>

        <div className={styles.message}>
          {/* <AiOutlineDesktop size={30} color={'#999EB0'} />  */}
          <p>Application only available on computer</p>
        </div>

      </div>
    </div>
  )
}
