import React , { useEffect , useState  , useCallback , useRef } from 'react';
import { useParams , Link, useLocation , useHistory } from 'react-router-dom';
import { RiArrowLeftSLine } from "react-icons/ri";

//Style
import styles from './ReadingGuide.module.scss';

//Action & context
import { useGlobalContext } from '../../context/GlobalContext';
import { postReadingGuideAction , putReadingGuideAction , getReadingGuideAction , deleteReadingGuideAction } from '../../actions/readingGuidesActions';
import { deleteFileAction } from '../../actions/recordsActions';
//Components
import Abstract from './Sections/Abstract/Abstract';
import Section from './Sections/Section/Section';
import SectionObjectives from './Sections/Section/SectionObjectives';
import Button from '../../components/lib/Button/Button';
import Documents from './Sections/Documents/Documents';
// import Modale from '../../components/lib/Modale/Modale';
// import Loader from '../../components/lib/Loader/Loader';
// import DocumentModale from '../../components/partials/DocumentModale/DocumentModale';

export default function ReadingGuide() {

  const [ context , dispatch ] = useGlobalContext();
  const location = useLocation();
  const history = useHistory();
  const params = useParams();
  const containerRef = useRef();
  const { query , recordsList } = context.records;
  const { readingGuide } = context.readingGuides
  const { isAdmin , toast } = context.auth

  const documentsOptions = recordsList.filter(r =>  r.type !== "trial").map(r => ({ value: r._id , label: r.title }))

  const links = [
    {
      name: "abstract",
      id: "abstract"
    },
    {
      name: "Introduction",
      id: "introduction"
    },
    {
      name: "Objectives",
      id: "objectives"
    },
    {
      name: "methodology",
      id: "methodology"
    },
    {
      name: "Materiel",
      id: "materiel"
    },
    {
      name: "Results",
      id: "result"
    },
    {
      name: "Conclusion",
      id: "conclusion"
    },
    {
      name: "Discussion / limits",
      id: "discussion"
    },
    {
      name: "Documents",
      id: "records"
    },
  ];

  const [ activeLink , setActiveLink ] = useState('abstract');
  const [ isPreview , setIsPreview ] = useState(true);
  const [ data , setData ] = useState({});
  const [ docIsLoading , setDocIsLoading ] = useState(false);

  const initData = useCallback(() => {
    if (params.id) {
      getReadingGuideAction(dispatch , params.id )
    } else {
      setIsPreview(false)
    }
  },[dispatch , params.id]);

  useEffect(() => {
    initData();
    document.getElementById("filters").style.opacity = 0
    document.getElementById("filters").style.pointerEvents = 'none'
    document.getElementById("nav").style.zIndex = 1
    return () => {
      document.getElementById("filters").style.opacity = 1
      document.getElementById("filters").style.pointerEvents = 'all'
      dispatch({
        type: 'GET_READING_GUIDE',
        payload : null
      });
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (readingGuide) {
      setData(readingGuide)
    }
  },[readingGuide])

  useEffect(() => {
    containerRef.current.scrollTo({top: 0, behavior: 'smooth'});
    setActiveLink('abstract')
  },[isPreview])


  useEffect(() => {
    if (toast?.message === "The document has been removed") {
      history.push('/library/' + location.search )
    }
    // eslint-disable-next-line
  },[toast]);

  function deleteReadingGuide() {
    deleteReadingGuideAction(dispatch , readingGuide._id)
  }

  function handleChange( value , key ,  obj = null ) {
    let form = Object.assign( data , {})
    if (obj) {
      if (!form[obj]) {
        form[obj] = {}
      }
      form[obj][key] = value;
    } else {
      form[key] = value
    }
    setData(form);
  }

  function srollToSection(id) {
    setActiveLink(id)
    containerRef.current.scrollTop = document.getElementById(id).offsetTop;
  }

  function submitForm() {
    if(readingGuide?._id) {
      putReadingGuideAction(dispatch , readingGuide._id , data )
    } else {
      postReadingGuideAction(dispatch , Object.assign( data, { topic: query.topic[0] }) )
    }
  }

  function handleChangePictures( value ,  obj ) {
    let updatedform = Object.assign( data , {})
    if (obj) {
      if (!updatedform[obj]) {
        updatedform[obj] = {}
      }
      updatedform[obj]["pictures"] = value;
    }
    if(readingGuide?._id) {
      putReadingGuideAction(dispatch , readingGuide._id , updatedform )
    } else {
      postReadingGuideAction(dispatch , Object.assign( updatedform , { topic: query.topic[0] }) )
    }
  }

  function handleChangeDocument(id) {
    let updatedform = Object.assign( data , {})
    let list = updatedform.records ? updatedform.records.map(r => r._id ) : [];
    if (list.find(r => r === id)) {
      deleteFileAction(dispatch , id );
      list = list.filter(r => r !== id);
    } else {
      list.push(id)
    }
    setDocIsLoading(true);
    if(readingGuide?._id) {
      putReadingGuideAction(dispatch , readingGuide._id , { records: list } )
    } else {
      updatedform["records"] = list;
      postReadingGuideAction(dispatch , Object.assign( updatedform , { topic: query.topic[0] }) )
    }
  }

  return (
    <div className={`page-container ${styles['page-container']}`}>
      <header className={styles.header}>
        <div className={styles.content}>
          <div className={styles.links}>
            <Link to={'/library/' + location.search }>
              <RiArrowLeftSLine size={26} color={"#20B9BC"}/>
              <p>back to the list</p>
            </Link>
            <ul>
              {links.filter(d => {
                if(!isPreview) return d
                if(d.id === "records" || d.id === "abstract" ) return d
                if(data[d.id]?.primary ) return d
                if(data[d.id]?.pictures?.length > 0 ) return d
                if(data[d.id]?.text?.length > 0 ) return d
                return null
              }).map((link, i ) => 
                <li
                  key={`link${i}`}
                  className={activeLink === link.id ? styles['is-active'] : ''}
                  onClick={() => srollToSection(link.id)}>
                  <div className={`${styles.puce} ${activeLink === link.id ? styles['is-active'] : ''}`}>
                  </div>
                  {link.name}
                </li>
              )}
            </ul>
          </div>
          <div className={`${styles.actions}`}>
            {isAdmin &&
              <>
                {!isPreview ?
                  <>
                    <Button
                      primary
                      onClick={() => setIsPreview(true)}
                      >
                      Preview
                    </Button>
                    <Button
                      primary
                      onClick={() => submitForm()}
                      >
                      save
                    </Button>
                    {readingGuide?._id &&
                      <Button
                        danger
                        onClick={() => deleteReadingGuide()}
                        >
                        Delete
                      </Button>
                    }
                  </>
                  :
                  <Button
                    primary
                    onClick={() => setIsPreview(false)}
                    >
                    Edit
                  </Button>
                }
              </>
            }
          </div>
        </div>
      </header>
      <div ref={containerRef} className={`${styles.container}`}>
        <div className={`content ${styles.content}`}>
          <Abstract
            id={"abstract"}
            setActiveLink={setActiveLink}
            isPreview={isPreview}
            handleChange={handleChange}
            data={data}
          />
          <Section
            name={'Abstract'}
            isPreview={isPreview}
            handleChangePictures={(val) => handleChangePictures(val , 'abstract')}
            handleChange={(value , key ) => handleChange( value , key , 'abstract')}
            setActiveLink={setActiveLink}
            data={data['abstract']}
            />
          <Section
            name={'Introduction'}
            id={"introduction"}
            isPreview={isPreview}
            handleChangePictures={(val) => handleChangePictures(val , 'introduction')}
            handleChange={(value , key ) => handleChange( value , key , 'introduction')}
            setActiveLink={setActiveLink}
            data={data['introduction']}
            />
          <SectionObjectives
            name={'Objectives'}
            id={"objectives"}
            isPreview={isPreview}
            handleChangePictures={(val) => handleChangePictures(val , 'objectives')}
            handleChange={(value , key ) => handleChange( value , key , 'objectives')}
            setActiveLink={setActiveLink}
            data={data['objectives']}
            />
          <Section
            name={'Methodology'}
            id={"methodology"}
            isPreview={isPreview}
            handleChangePictures={(val) => handleChangePictures(val , 'methodology')}
            handleChange={(value , key ) => handleChange( value , key , 'methodology')}
            setActiveLink={setActiveLink}
            data={data['methodology']}
            />
          <Section
            name={'Materiel'}
            id={"materiel"}
            isPreview={isPreview}
            handleChangePictures={(val) => handleChangePictures(val , 'materiel')}
            handleChange={(value , key ) => handleChange( value , key , 'materiel')}
            setActiveLink={setActiveLink}
            data={data['materiel']}
            />
          <Section
            name={'Results'}
            id={"result"}
            isPreview={isPreview}
            handleChangePictures={(val) => handleChangePictures(val , 'result')}
            handleChange={(value , key ) => handleChange( value , key , 'result')}
            setActiveLink={setActiveLink}
            data={data['result']}
            />
          <Section
            name={'Conclusion'}
            id={"conclusion"}
            isPreview={isPreview}
            handleChangePictures={(val) => handleChangePictures(val , 'conclusion')}
            handleChange={(value , key ) => handleChange( value , key , 'conclusion')}
            setActiveLink={setActiveLink}
            data={data['conclusion']}
            />
          <Section
            name={'Discussion'}
            id={"discussion"}
            isPreview={isPreview}
            handleChangePictures={(val) => handleChangePictures(val , 'discussion')}
            handleChange={(value , key ) => handleChange( value , key , 'discussion')}
            setActiveLink={setActiveLink}
            data={data['discussion']}
            />
          <Documents 
            name={'Documents'}
            id={"records"}
            isPreview={isPreview}
            handleChange={(value) => handleChangeDocument( value )}
            options={documentsOptions ? documentsOptions : []}
            isLoading={docIsLoading}
            setIsLoading={setDocIsLoading}
            setActiveLink={setActiveLink}
            data={data['records']}
            />
        </div>
      </div>
    </div>

  )
}