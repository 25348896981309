import React from 'react'
import PicturesList from './PicturesList/PicturesList'
import Textarea from '../../../../components/lib/Textarea/Textarea'

import styles from './Section.module.scss'

export default function Section( { id , name , isPreview , data , handleChange , handleChangePictures , setActiveLink }) {

  function getClassNames() {
    let classNames = styles.section
    if (isPreview ) {
      if (!data?.pictures || !data?.text) {
        classNames += ` ${styles['d-none']}`
      }
    }
    return classNames
  }
  
  return (
    <section
      id={id}
      className={getClassNames()}
      onMouseOver={() => id ? setActiveLink(id) : null }>
      {isPreview ?
        <>
          <h2>{name}</h2>
          <PicturesList
            isPreview={isPreview}
            name={name}
            list={data?.pictures ? data?.pictures : []}
            handleChange={(val) => handleChangePictures(val)}
            />
          <p>{data?.text}</p>
        </>
        :
        <>
          <h2>{name}</h2>
          <PicturesList
            isPreview={isPreview}
            name={name}
            list={data?.pictures ? data?.pictures : []}
            handleChange={(val) => handleChangePictures(val)}
            />
          <Textarea
            value={data?.text ? data.text : '' }
            onChange={(val => handleChange(val , 'text'))}
          />
        </>
      }
    </section>
  )
}
