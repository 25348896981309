import React , { useRef , useEffect } from 'react';
import { useLocation , useHistory  } from 'react-router-dom';
//Style
import styles from './Datavision.module.scss';

//golbal context
import { useGlobalContext } from '../../context/GlobalContext';

//Components
import DatavizLoader from '../../components/partials/DatavizLoader/DatavizLoader';
import Nodes from '../../components/partials/Nodes/Nodes';
//Utils
import { setQueryUrl } from '../../utils/utils';
import { getAuthorAction } from '../../actions/recordsActions';

export default function DatavisionPage() {

  const [context , dispatch ]  = useGlobalContext();
  const containerRef = useRef();
  const { pathname } = useLocation();
  const history = useHistory();


  const { query, optionsLists } = context.records;

  const datavision = context.dataviz?.datavision;

  useEffect(() => {
    return () => {
      dispatch({
        type: 'GET_DATAVISION',
        payload: null
      })
    }
    // eslint-disable-next-line
  }, [])

  function handleChangeKeywords(word) {
    let data = query;
    let newList = data?.keywords || [];
    if (newList.find(d => d === word )) {
      newList = newList.filter(d => d !== word );
    } else {
      newList.push(word)
    }
    data.authors = data?.authors || [];
    data.keywords = newList.length > 0 ? newList : null;
    history.push(pathname + setQueryUrl(data) )
  }

  function handleChangeAuthors(author) {
    let data = query;
    let newList = data?.authors || [];
    if (newList.find(d => d === author )) {
      newList = newList.filter(d => d !== author );
    } else {
      newList.push(author);
      getAuthorAction(dispatch ,{ name: author })
    }
    data.authors = newList.length > 0 ? newList : null;
    data.keywords = data?.keywords?.length > 0 ? data.keywords : [];
    history.push(pathname + setQueryUrl(data) )
  }

  return (
    <div ref={containerRef} className={`page-container ${styles['page-container']}`}>
      <DatavizLoader isLoading={context.dataviz.isLoading} />
      {containerRef?.current?.clientHeight && datavision?.nodes?.length < 2000 ?
        <Nodes
          data={datavision}
          authors={optionsLists?.authors}
          handleChangeKeywords={handleChangeKeywords}
          handleChangeAuthors={handleChangeAuthors}
          width={containerRef.current.clientWidth}
          height={containerRef.current.clientHeight}
        />
        :
        <div className={styles.message}>
          {!context.dataviz.isLoading && datavision?.nodes?.length > 0 && <p>{datavision?.nodes?.length} keyswords and authors</p>}
          {!context.dataviz.isLoading && !datavision?.nodes && <p>0 keyswords and authors</p>}
        </div>
      }
    </div>
  )
}