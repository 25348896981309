import React from 'react'
import styles from './Abstract.module.scss'
import Input from '../../../../components/lib/Input/Input';

export default function Abstract({ id , isPreview , data , handleChange , setActiveLink }) {
  return (
    <div id={id} onMouseOver={() => setActiveLink(id)} className={styles.abstract}>
      {isPreview ?
        <>
          <h1>{data?.title}</h1>
          <div className={styles.info}>
            {data?.journal && <p>{data.journal}</p>}
            {data?.year && <p>{data.year}</p>}
          </div>
        </>
        :
        <>
          <div className={styles.title}>
            <Input
              label={"Title"}
              placehloder={"Title"}
              name={"title"}
              value={data?.title ? data.title : '' }
              onChange={(val) => handleChange(val , 'title')}
            />
          </div>
          <div className={styles.row}>
            <div className={styles.col}>
              <Input
                label={"Journal"}
                placehloder={"Journal"}
                name={"journal"}
                value={data?.journal ? data.journal : '' }
                onChange={(val) => handleChange(val , 'journal')}
              />
            </div>
            <div className={styles.col}>
              <Input
                label={"Year"}
                placehloder={"YYYY"}
                name={"year"}
                value={data?.year ? data.year : '' }
                onChange={(val) => handleChange(val , 'year')}
              />
            </div>
          </div>
        </>
      }
      
    </div>
  )
}
