import React , { useRef } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { IoFlaskOutline  } from 'react-icons/io5';
import { VscFile } from 'react-icons/vsc';

import { useGlobalContext } from '../../context/GlobalContext';

import Card from '../../components/partials/Card/Card';
import Top from '../../components/partials/Top/Top';
import Map from '../../components/partials/Map/Map';
import DatavizLoader from '../../components/partials/DatavizLoader/DatavizLoader';

import styles from './Dashboard.module.scss';

export default function DashboardPage() {
  const location = useLocation();
  const context  = useGlobalContext()[0];
  const dashboard = context.dataviz?.dashboard;
  const containerRef = useRef();
  const locations = { countries: dashboard?.countries , cities: [] }

  return (
    <div className={`page-container ${styles['page-container']}`}>
     <DatavizLoader isLoading={context.dataviz.isLoading} />
      <div className={`${styles.row}`}>
        <div className={`${styles.col}`}>
          <h2><VscFile size={24} /> Last publication</h2>
          <div className={styles['card-list']}>
          {dashboard?.lastPublications?.map((d , i) => <Card doc={d} key={"lastPublication" + i}/>)}
          </div>
        </div>
        <div className={`${styles.col}`}>
          <h2><IoFlaskOutline size={25} /> Last trials</h2>
          <div className={styles['card-list']}>
          {dashboard?.lastTrials?.map((d , i) => <Card doc={d} key={"lastTrials" + i}/>)}
          </div>
        </div>
      </div>
      <div className={`${styles.row}`}>
        <div className={`${styles.col}`}>
          <Link to={"/map/" + location.search }>
            <h2>GEOGRAPHIC VIEW</h2>
          </Link>
          <div ref={containerRef} className={styles.map}>
            {containerRef?.current?.clientHeight && locations?.countries && 
              <Map
                data={locations}
                enableZoom={false}
                isDashboard
                mapWidth={containerRef?.current?.clientWidth}
                mapHeight={containerRef?.current?.clientHeight}
                mapId={"dashboard-page"} />
            }
          </div>
        </div>
        <div className={`${styles['col-top']}`}>
          {dashboard?.top10keywords?.length > 0 &&
            <div className={`${styles.top}`}>
              <Link to={"/datavision/" + location.search }>
                <h2>TOP 10 TAGS</h2>
              </Link>
              <div className={styles['top-list']}>
                {dashboard?.top10keywords.map((d , i) => <Top name={d.value} data={d} type="keywords" max={dashboard?.top10keywords[0].size} value={d.size} key={"tags" + i}/>)}
              </div>
            </div>
          }
          {dashboard?.top10authors?.length > 0 &&
            <div className={`${styles.top}`}>
              <Link to={"/author/" + location.search }>
                <h2>TOP 10 AUTHORS</h2>
              </Link>
              <div className={styles['top-list']}>
                {dashboard?.top10authors.map((d , i) => <Top name={d.value} data={d} type="authors" isAuthor max={dashboard?.top10authors[0].size} value={d.size} key={"authors" + i}/>)}
              </div>
            </div>
          }
        </div>
      </div>
    </div>
  )
}
