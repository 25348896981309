import React, { useState , useEffect , useRef } from 'react';
import { BiMinus } from "react-icons/bi";
import { BsPlus } from "react-icons/bs";
import { MdRadioButtonChecked , MdRadioButtonUnchecked } from "react-icons/md";

export default function FilterList({ name , filterIsOpen , styles , list , selected , isMulti , setSelected }) {

  const [ listIsOpen , setListIsOpen  ] = useState(false);
  const listRef = useRef();

  useEffect(() => {
    if (!filterIsOpen) setListIsOpen(false)
  }, [filterIsOpen])

  function handleChange(val) {
    if (!isMulti) return setSelected([val]);
    let newList = [...selected];
    if (selected.find(d => d === val )) {
      newList = selected.filter(item => item !== val);
    } else {
      newList.push(val)
    }
    return setSelected(newList);
  }

  return (
    <>
      <p>{name} {isMulti && selected.length > 0 && <span>({selected.length})</span>} </p>
      <button
        onClick={() => setListIsOpen(!listIsOpen)}
        >
        {listIsOpen ? <BiMinus size={20} color={"#FFFFFF"}/> : <BsPlus size={20} color={"#FFFFFF"}/>}
      </button>
        <div className={styles.list} style={{ maxHeight: 300, height : listIsOpen ? listRef.current.clientHeight + 30 : 0 }}>
          <div ref={listRef} className={styles['list-content']}>
          {list?.map((item , i) => {
            let isActive = selected?.find(d => d === item.value );
            return (
              <div key={i}
                onClick={() => handleChange(item.value)}
                className={`${styles.item} ${isActive ? `${styles.isActive}` : ""}`}>
                <div className={styles.option}>
                  {isActive ? <MdRadioButtonChecked size={14} color={"#FFFFFF"}/> : <MdRadioButtonUnchecked size={14} color={"#FFFFFF"}/> }
                  <p>{item.label}</p> 
                </div>
              </div>
          )})}
        </div>
      </div>
    </>
  )
}
