import React from 'react'
import { Link , useLocation } from 'react-router-dom';
import styles from './ReadingGuideRow.module.scss';
import { format } from 'date-fns';
import { VscSymbolFile  } from 'react-icons/vsc';

export default function ReadingGuideRow({ doc }) {

  const location = useLocation();

  return (
    <Link to={`/library/reading-guide/${doc._id}/${location.search}`}>
      <div className={styles.container} style={{ animationDelay: .08 * doc.index + 's'}}>
        <div className={styles.icon}>
          <VscSymbolFile size={24} />
        </div>
        <div className={styles.title}>
          <p>{doc.title.length >= 90 ? `${doc.title.slice(0, 90)} ...` : doc.title }</p>
        </div>
        <div className={styles.info}>
          <p>{doc?.authors?.length > 0 ? doc.authors?.length : 0 } authors</p>
        </div>
        <div className={styles.info}>
          <p>{format(new Date(doc.date) , 'yyyy/MM/dd')}</p>
        </div>
      </div>
    </Link>
  )
}
