import React from 'react';
import { useForm } from "react-hook-form";

//styles & images
import styles from './Account.module.scss';
import illu from '../../assets/images/account.svg';

//components
import Button from '../../components/lib/Button/Button';
import Input from '../../components/lib/Input/Input';

//Global state
import { useGlobalContext } from '../../context/GlobalContext';
import { putUserAuthAction } from '../../actions/authActions';

export default function Account() {

  const [ context, dispatch ] = useGlobalContext();

  const { userAuth } = context.auth

  const { handleSubmit, register, errors } = useForm();

  function submitUser(values) {
    putUserAuthAction(dispatch , values)
  }

  return (
    <div className={`page-container ${styles.container}`}>
      <div className="content">
        <div className={styles.row}>
          {userAuth &&
            <form onSubmit={handleSubmit(submitUser)} className={styles.col}>

              <h2>Mon compte</h2>

              <div className={styles['info-row']}>

                <div className={styles['info-col']}>
                  <Input
                    label={"Nom"}
                    name={"profile.lastName"}
                    placehloder={"Nom"}
                    defaultValue={userAuth?.lastName}
                    register={register({
                      required: "le nom est obligatoire",
                    })}
                    withErrors={true}
                    error={errors?.profile?.lastName?.message}
                  />
                </div>

                <div className={styles['info-col']}>
                  <Input
                    label={"Prénom"}
                    name={"profile.firstName"}
                    placehloder={"Prénom"}
                    defaultValue={userAuth?.firstName}
                    register={register({
                      required: "le prénom est obligatoire",
                    })}
                    withErrors={true}
                    error={errors?.profile?.firstName?.message}
                  />
                </div>

              </div>

              <div className={styles['info-row']}>

                <div className={styles['info-col']}>
                  <Input
                    label={"Mail"}
                    name={"email"}
                    placehloder={"mail@mail.fr"}
                    defaultValue={userAuth?.email}
                    register={register({
                      required: "le mail est obligatoire",
                    })}
                    withErrors={true}
                    error={errors?.email?.message}
                  />
                </div>

                <div className={styles['info-col']}>
      
                </div>

              </div>
                    
              <div className={styles['btn-container']}>
                <Button
                  primary
                  type="submit"
                  className={`${styles.btn}`}
                  >
                  <p>VALIDER</p>
                </Button>
              </div>

            </form>
          }

          <div className={`${styles.col} ${styles.illu}`}>
            <img src={illu} alt="illu" />
          </div>

        </div>

      </div>
    </div>
  )
}
