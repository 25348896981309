import React from 'react'
import PicturesList from './PicturesList/PicturesList'
import Textarea from '../../../../components/lib/Textarea/Textarea'

import styles from './Section.module.scss'

export default function SectionObjectives( { id , name , isPreview , data , handleChange , handleChangePictures , setActiveLink }) {

  function getClassNames() {
    let classNames = styles.section
    if (isPreview ) {
      if (!data?.primary && !data?.secondary) {
        classNames += ` ${styles['d-none']}`
      }
    }
    return classNames
  }

  return (
    <section
      id={id}
      className={getClassNames()}
      onMouseOver={() => id ? setActiveLink(id) : null }>
      {isPreview ?
        <>
          <h2>{name}</h2>
          {data?.primary &&
            <>
              <h3>Primary</h3>
              <p>{data?.primary}</p>
            </>
          }
          {data?.secondary &&
            <>
            <h3>Secondary</h3>
            <p>{data?.secondary}</p>
            </>
          }
        </>
        :
        <>
          <h2>{name}</h2>
          <PicturesList
            isPreview={isPreview}
            list={data?.pictures ? data?.pictures : []}
            handleChange={(val) => handleChangePictures(val)}
            />
          <h3>Primary</h3>
          <Textarea
            value={data?.primary ? data.primary : '' }
            onChange={(val => handleChange(val , 'primary'))}
          />
          <h3>Secondary</h3>
          <Textarea
            value={data?.secondary ? data.secondary : '' }
            onChange={(val => handleChange(val , 'secondary'))}
          />
        </>
      }
    </section>
  )
}
