import {
  ERROR_DATAVIZ,
  GET_LOCATIONS,
  GET_DASHBOARD,
  GET_DATAVISION,
  GET_TIMELINE,
  DATAVIZ_IS_LOADING
} from "../actions/types";

const initialState = {
  error: null,
  locations: null,
  timeline: null,
  datavision: null,
  isLoading: false,
};

export default function datavizReducer(state = initialState, action) {

  switch (action.type) {
    case DATAVIZ_IS_LOADING:
      return {...state , isLoading: action.payload }
    case GET_TIMELINE:
      return {...state , timeline: action.payload , isLoading: false }
    case GET_DATAVISION:
      return {...state , datavision: action.payload , isLoading: false }
    case GET_DASHBOARD:
      return {...state , dashboard: action.payload , isLoading: false }
    case GET_LOCATIONS:
      return {...state , locations: action.payload , isLoading: false }
    case ERROR_DATAVIZ:
      return {...state , error: action.payload , isLoading: false }
    default:
      return {...state};
  }
}