import React, { useState , useEffect } from 'react';
import * as QueryString from "query-string";
import { useHistory, useLocation } from 'react-router-dom';
import { BsFilterLeft } from "react-icons/bs";
import { RiArrowLeftSLine } from "react-icons/ri";
import { AiOutlineClockCircle , AiFillClockCircle } from "react-icons/ai";
import { BiSearch } from "react-icons/bi";

import { useGlobalContext } from '../../../context/GlobalContext';
import { setQueryUrl } from '../../../utils/utils';
import FilterList from '../Filters/partials/FilterList';
import FilterTags from '../Filters/partials/FilterTags';
import TimeLine from '../Filters/partials/TimeLine/Timeline';
import styles from './filters.module.scss';

export default function Filters({ isDisabled , pathname }) {

  const [ context, dispatch ] = useGlobalContext();
  const location = useLocation();
  let queryFilters = QueryString.parse(location.search);

  const { query , optionsLists , libraryIsLoading } = context.records;
  const dates = context.records?.dates;
  const history = useHistory();

  const [ filterIsOpen , setFiltersIsOpen ] = useState(false);
  const [ timelineIsOpen , setTimelineIsOpen ] = useState(false);
  const [ isLibrary , setIsLibrary ] = useState(false);
  const [ search , setSearch ] = useState();
  const [ seletedTags , setSelectedTags ] = useState([]);
  const [ tagsOptions , setTagsOptions ] = useState([]);

  useEffect(() => {
    setIsLibrary(pathname === '/library/' ? true: false );
  }, [pathname]);

  useEffect(() => {
    if (isDisabled) {
      setFiltersIsOpen(false);
    }
  }, [isDisabled]);

  useEffect(() => {
    if (dates?.newest) {
      setTimelineIsOpen(true);
    }
  }, [dates]);


  useEffect(() => {
    let options = [];
    for (const key in optionsLists) {
      if (optionsLists[key]) {
        if (key === "countries" || key === "cities" || key === "keywords" || key === "authors") {
          optionsLists[key].forEach((d) => 
            options.unshift({ value: { value: d , type: key } , label: d })  
          )
        }
      }
    }
    setTagsOptions(options);
    let selected = [];
    for (const key in query) {
      if (query[key] && Array.isArray(query[key])) {
        if (key === "countries" || key === "cities" || key === "keywords"|| key === "authors") {
          query[key].forEach(d => {
            let found = options.find(opt => opt.label === d);
            if (found) selected.push(found)
          });
        }
      }
    }
    setSelectedTags(selected);

  }, [query , optionsLists]);

  useEffect(() => {
    dispatch({
      type: 'LIBRARY_IS_LOADING',
      payload: false
    });
    // eslint-disable-next-line
  },[optionsLists]);

  useEffect(() => {
    const mainContainer = document.getElementsByClassName("app-container")[0]
    if (libraryIsLoading) {
      mainContainer.style.cursor = "wait"
      mainContainer.style.pointerEvents = "none"
    } else {
      mainContainer.style.cursor = "auto"
      mainContainer.style.pointerEvents = "all"
    }
    // eslint-disable-next-line
  },[libraryIsLoading]);

  function handleChangeFilters(key , value ) {
    let data = Object.assign({} , query);
    data.authors = queryFilters?.authors ? queryFilters?.authors?.split(',') : [];
    if (key === "filterType") {
      data.filterType = query.filterType === "or" ? "and" : "or";
    } else if (key === "topic") {
      data.countries = [];
      data.cities = [];
      data.keywords = [];
      data.authors = [];
      data[key] = value
    } else if (key === "tags") {
      setSelectedTags(value)
      let tags = {
        countries: [],
        cities: [],
        keywords: [],
        authors: []
      }
      value.forEach((opt) => {
        tags[opt.value.type].push(opt.label);
      });
      data = Object.assign(data , tags);
    } else {
      data[key] = value
    }
    data.page = 1
    dispatch({
      type: 'RESET_RECORDS'
    })
    dispatch({
      type: 'LIBRARY_IS_LOADING',
      payload: true
    })
    // history.replace({ search: setQueryUrl(data)});
    history.push(pathname + setQueryUrl(data))
  }

  function getClassNames() {
    let classNames = styles.filters
    if (isDisabled) {
      classNames += ` ${styles.disabled}`;
      return classNames
    }
    if (filterIsOpen) {
      classNames += ` ${styles.isOpen}`
    }
    if (isLibrary) {
      classNames += ` ${styles.library}`
    }
    if (libraryIsLoading) {
      classNames += ` ${styles.loading}`
    }
    return classNames
  }

  return (
    <>
      <div className={getClassNames()}>
        <button className={styles["btn-filters"]} onClick={() => setTimelineIsOpen(!timelineIsOpen)}>
        {timelineIsOpen? <AiFillClockCircle size={24} color={"#FFFFFF"}/> : <AiOutlineClockCircle size={24} color={"#FFFFFF"}/> }
        </button>
        <button className={`${styles["btn-filters"]} ${styles["border"]}`} onClick={() => setFiltersIsOpen(!filterIsOpen)}>
        {filterIsOpen? <RiArrowLeftSLine size={28} color={"#FFFFFF"}/>  : <BsFilterLeft size={30} color={"#FFFFFF"}/> }
        <div className={styles.count}>
          {seletedTags.length}
        </div>
        </button>
        <div className={`${styles.filter} ${isLibrary ? styles.library :""} ${filterIsOpen ? `${styles.isOpen} ${styles.border}` : ""}`}>
          <input
            value={search ? search :  "" }
            onChange={(e) => setSearch(e.target.value)}
            placeholder="Search"
            />
          <button
            className={styles["btn-search"]} 
            onClick={() => handleChangeFilters( "search" , search ? search.toLowerCase() : null )}
            >
            <BiSearch size={18} color={"#FFFFFF"}/>
          </button>
        </div>

        <div className={`${styles.filter} ${filterIsOpen ? `${styles.filter} ${styles.isOpen} ${styles.border}` : ""}`}>
          <FilterList
            name={"Type"}
            styles={styles}
            filterIsOpen={filterIsOpen}
            list={optionsLists?.type ? optionsLists?.type: []}
            selected={query.type ? query.type : []}
            isMulti
            setSelected={(val) => handleChangeFilters("type",val)}
            />
        </div>

        <div className={`${styles.filter} ${filterIsOpen ? `${styles.filter} ${styles.isOpen} ${styles.border}` : ""}`}>
          <FilterList
            name={query?.topic[0] ? optionsLists?.topic.find((t) => t.value === query?.topic[0])?.label  : "Topics"}
            styles={styles}
            filterIsOpen={filterIsOpen}
            list={optionsLists?.topic ? optionsLists?.topic: []}
            selected={query.topic ? query.topic : []}
            setSelected={(val) => handleChangeFilters("topic",val)}
            />
        </div>

        <div className={`${styles.filter} ${filterIsOpen ? `${styles.filter} ${styles.isOpen} ${styles.border}` : ""}`}>
          <FilterTags
            name={"Tags"}
            styles={styles}
            filterIsOpen={filterIsOpen}
            filterType={query.filterType}
            list={tagsOptions ? tagsOptions : []}
            selected={seletedTags ? seletedTags : []}
            handleChangeFilterType={() => handleChangeFilters("filterType")}
            setSelected={(val) => handleChangeFilters("tags",val)}
            />
        </div>

        {isLibrary &&
          <div className={`${styles.filter} ${filterIsOpen ? `${styles.filter} ${styles.isOpen} ${styles.border}` : ""}`}>
            <FilterList
              name={"Sort by"}
              styles={styles}
              filterIsOpen={filterIsOpen}
              list={['alphabetic' , 'publicationDate'].map(d => ({ value: d , label: d}))}
              selected={query.sortBy ? [query.sortBy] : []}
              setSelected={(val) => handleChangeFilters("sortBy",val)}
              />
          </div>
        }

      </div>
      <TimeLine isOpen={!isDisabled && timelineIsOpen}/>
    </>
  )
}
