import React from 'react'
import { useSpring, animated } from 'react-spring';
import * as QueryString from "query-string";
import * as d3 from 'd3';

import styles from './Top.module.scss';
import { useHistory, useLocation } from 'react-router-dom';
import { setQueryUrl } from '../../../utils/utils';
import { useGlobalContext } from '../../../context/GlobalContext';
import { getAuthorAction } from '../../../actions/recordsActions';

export default function Top({ name , data, type,  value , max }) {
  const [context , dispatch ]= useGlobalContext();

  const history = useHistory()
  const location = useLocation();
  const { search } = location;
  let queryFilters = QueryString.parse(search);

  const { query } = context.records;

  var scale = d3.scaleLinear()
  .domain([0, max])
  .range([0,  100 ]);

  const animatedValue = useSpring({ number: value , from: { number: 0 } });
  const barSize = useSpring({ width: `${scale(value)}%`  , from: { width: `${0}%` } });

  function handleChangeQuery(key, value) {
    const queryData =  query;
    const authors = queryFilters?.authors ? queryFilters?.authors?.split(',') : [];
    const keywords = queryFilters?.keywords ? queryFilters?.keywords?.split(',') : [];
    if(key === "authors") {
      getAuthorAction(dispatch, { name: value });
      queryData.authors = [...authors, value]
    } else if(key === "keywords") {
      queryData.keywords = [...keywords, value]
      queryData.authors = authors;
    }
    history.push('/datavision/' + setQueryUrl(queryData) )
  }

  return (
    <div className={styles.container}>
      <div onClick={() => handleChangeQuery(type, data.value)} className={styles.labels}>
        <p className={styles.name}>{name}</p>
        <p><animated.span>{animatedValue.number.interpolate(x => x.toFixed(0))}</animated.span></p>
      </div>
      <div className={styles.bar}>
        <animated.div className={styles["range-bar"]} style={barSize}></animated.div>
      </div>
    </div>
  )
}
