import React, {useEffect, useCallback} from 'react';
import {useGlobalContext} from '../context/GlobalContext';
import {Switch, Route, useLocation, useHistory} from 'react-router-dom';
import {useTransition, animated} from 'react-spring';
import {setVhUnit} from '../utils/utils';

// AUTH ACTION
import {getUserAuthAction} from '../actions/authActions';

//PARTIALS
import Toaster from '../components/lib/Toaster/Toaster';
import Nav from '../components/partials/Nav/Nav';

//PAGES
import SwitchPage from './Auth/SwitchPage';
import LoginPage from './Auth/LoginPage';
import IndexPage from './Auth/IndexPage';
import SsoPage from './Auth/SsoPage';
import AccountPage from './Account/AccountPage';
import LibraryPage from './Library/LibraryPage';
import GeographyPage from './Geography/GeographyPage';
import TopicsPage from './Topics/TopicsPage';
import DashBoardPage from './Dashboard/DashboardPage';
import DatavisionPage from './Datavision/DatavisionPage';
import TrialsTimelinePage from './Timeline/TrialsTimelinePage';
import InternalRecordPage from './InternalRecord/InternalRecordPage';
import ReadingGuidePage from './ReadingGuide/ReadingGuidePage';
import AuthorsPage from './Authors/AuthorsPage';
import AuthorPanel from '../components/partials/AuthorPanel/AuthorPanel';
// import DataPolicy from '../pages/Legal/DataPolicy';import Legal from
// '../pages/Legal/Legal'; import Transparency from
// '../pages/Legal/Transparency';

export default function GlobalRouter() {

  const [context,  dispatch] = useGlobalContext();
  const { author, query } = context.records;

  const location = useLocation();
  const history = useHistory();

  const transitions = useTransition(location, location => location.pathname, {
		from: {
			opacity: 1,
			transform: 'translate3d(-100%, 0,0)'
		},
		enter: {
			opacity: 1,
			transform: 'translate3d(0,0,0)'
		},
		leave: {
			opacity: 1,
			transform: 'translate3d(100%, 0 ,0)'
		}
  });

  const getUser = useCallback(() => {
    getUserAuthAction(dispatch);
  }, [dispatch]);

  useEffect(() => {
		getUser();
		setVhUnit();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
		if (location.pathname !== "/login" && context.auth.error === 401) {
			logout();
		}
    // eslint-disable-next-line
  }, [context.auth.error]);

  function logout() {
		history.push('/index');
		localStorage.removeItem('token');
		dispatch({type: 'IS_AUTH', payload: false});
		dispatch({type: 'USER_AUTH', payload: null});
  };

  return (
		<>
			<Nav logout={logout}/>
			{transitions.map(({item: location, props, key}) => (
				<animated.div key={key} style={props}>
					<Switch location={location}>

						<Route exact path='/' render={() => <SwitchPage/>}/>

            <Route exact path='/index' render={() => <IndexPage/>}/>

            <Route exact path='/sso/:token' render={() => <SsoPage />}/>

						<Route exact path='/login' render={() => <LoginPage/>}/>

						<Route exact path='/account' render={() => <AccountPage/>}/>

						<Route exact path='/topics' render={() => <TopicsPage/>}/>

						<Route exact path='/dashboard' render={() => <DashBoardPage/>}/>

						<Route exact path='/datavision' render={() => <DatavisionPage/>}/>

						<Route exact path='/map' render={() => <GeographyPage/>}/>

						<Route exact path='/timeline' render={() => <TrialsTimelinePage/>}/>

						<Route exact path='/library/' render={() => <LibraryPage/>}/>

            <Route exact path='/author/' render={() => <AuthorsPage />}/>

						<Route exact path='/library/reading-guide' render={() => <ReadingGuidePage/>}/>

						<Route exact path='/library/reading-guide/:id' render={() => <ReadingGuidePage/>}/>

						<Route exact path='/library/intern/:docId' render={() => <InternalRecordPage/>}/>

						</Switch>
				</animated.div>
			))}
      <AuthorPanel author={author} query={query} />
			<Toaster
				toast={context.auth.toast}
				dispatch={dispatch}
				/>
		</>
  )
}