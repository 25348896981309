import React from 'react';
import styles from './DatavizLoader.module.scss';

export default function DatavizLoader({ isLoading , className }) {
  return (
    <div className={`${styles['dataviz-loader']} ${isLoading ? styles['is-active'] : ""} ${className? className : ""}`}>
      <svg version="1.1" id="L9" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
        viewBox="0 0 100 100" enableBackground="new 0 0 0 0" >
        <path fill="#20B9BC" d="M73,50c0-12.7-10.3-23-23-23S27,37.3,27,50 M30.9,50c0-10.5,8.5-19.1,19.1-19.1S69.1,39.5,69.1,50">
        </path>
      </svg>
    </div>

  )
}
