import React , { useEffect , useState , useCallback } from 'react';
import { useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";

import { FaEye , FaEyeSlash } from "react-icons/fa";
import { useGlobalContext } from '../../context/GlobalContext';
import { loginAction , forgotPasswordAction , getUserAuthAction } from '../../actions/authActions';

import styles from './Auth.module.scss';
import { TOOL_ID } from '../../actions';

export default function Login() {

  const { handleSubmit, register, errors } = useForm();

  const history = useHistory();

  const [ context, dispatch ] = useGlobalContext();

  const [ displayLogin , setDisplayLogin ] = useState(true);
  const [ errorForgot , setErrorForgot ] = useState();
  const [ showPassword , setShowPassword ] = useState(false);

  const getUser = useCallback(() => {
    getUserAuthAction(dispatch);
  },[dispatch]);

  useEffect(() => {
    if (context.auth.error && context.auth.error.status === 422) {
      setErrorForgot("Votre demande n'a pas pu être traitée telle qu'elle a été saisie. Veuillez réessayer.")
    }
  }, [context.auth.error]);

  useEffect(() => {
    if(context.auth.isAuth) {
      getUser();
    }
  // eslint-disable-next-line 
  }, [context.auth.isAuth]);

  useEffect(() => {
    if(context.auth.userAuth) {
      if (context.auth.userAuth?.tools.find((t) => t.tool === TOOL_ID )) {
        history.push("/topics");
      }
    }
  // eslint-disable-next-line 
  }, [context.auth.userAuth]);


  const backFromReset = () => {
    setDisplayLogin(true)
    const obj = {}
    obj.message = null
    dispatch({
      type: 'FORGOT_PASSWORD',
      payload: obj,
    });
  }


  const submitLogin = (values)  => {
    loginAction( dispatch , values);
    dispatch({
      type: 'ERROR_AUTH',
      payload: null,
    });
  };

  const submitReset = (values)  => {
    forgotPasswordAction( dispatch , values);
    dispatch({
      type: 'ERROR_AUTH',
      payload: null,
    });
  };


  function renderLoginForm() {
    return (
      <form className={styles['form-login']} onSubmit={handleSubmit(submitLogin)}>

        <label htmlFor="email">Email</label>
        <input
          name="email"
          className={`${styles.primary}`}
          ref={register({
            required: "Complete your email",
            pattern: {
              value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
              message: "Invalid email address"
            }
          })}
        />

        <p className="text-error">{errors.email && errors.email.message}</p>

        <label htmlFor="password">Password</label>
        <input
          className={`${styles.primary}`}
          name="password"
          type={showPassword? "text" : "password"}
          ref={register({
            required: "Complete your password",
          })}
        />
        <p className="text-error">{errors.password && errors.password.message}</p>
        <div
          className={styles.eye}
          onClick={() => setShowPassword(!showPassword)}>
          {showPassword ? <FaEye size={20} color={"#192243"} /> : <FaEyeSlash size={20} color={"#192243"} /> }
        </div>
        
        <div className={styles['btn-container']}>
          <button
            className={`${styles.btn} ${styles.primary}`}
            type="submit">login
          </button>
          <button onClick={() => setDisplayLogin(false)} className={styles['btn-reset']} >
            Forgot password ?
          </button>
        </div>


        <p className="text-error text-center">{context.auth.errorLogin && context.auth.errorLogin === 401 && <span> Invalid email address or password.</span>}</p>
    
      </form>
    )
  }

  function renderResetForm() {
    if (context.auth && context.auth.message && context.auth.message.forgot) {
      return(
        <div className={`${styles['form-reset']} ${styles['message']}`}>

          <p className="text-center">Please check your email address to know<br/>the link to reset your password.</p>

          <button
            onClick={() =>  backFromReset()}
            className={`${styles.btn} ${styles.primary}`}>
            Back to login
          </button>
        </div>
      )

    } else {
      return (
        <form className={styles['form-reset']} onSubmit={handleSubmit(submitReset)}>
        
          <label htmlFor="email">Email address</label>
          <input
            className={`${styles.primary}`}
            name="email"
            ref={register({
              required: "Complete your email",
              pattern: {
                value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                message: "Invalid email address"
              }
            })}
          />

          {errorForgot? 
            <p className="text-error text-center" style={{ margin: 5}}>{errorForgot}</p>
            :
            <p className="text-error text-center">{errors.email && errors.email.message}</p>
          }
          
          <div className={styles['btn-container']}>
            <button
              className={`${styles.btn} ${styles.primary}`}
              type="submit">
              Reset password
            </button>
            <button onClick={() => setDisplayLogin(true)}
              className={styles['btn-reset']}>
              Back to login
            </button>
          </div>
      
        </form>
      )
    }
  }
  
  return (
    <div className={styles.login}>

      <div className={styles['col-img']}></div>

      <div className={styles['col-form']}>

        <p className={`${styles.title} bold`}>
          <span className={`${styles.name} bold`} >BIBLIOCTA</span><br/>
          <span className="regular">BY </span><span>octapharma</span>
        </p>

        {displayLogin ?
          renderLoginForm()
          :
          renderResetForm()
        }

      </div>

    </div>
  )
}
