import React , { useRef } from 'react'
import { Link , useLocation } from 'react-router-dom';
import styles from './DocumentRow.module.scss';
import { format } from 'date-fns';
import { IoNewspaperOutline , IoFlaskOutline  } from 'react-icons/io5';
import { VscFile } from 'react-icons/vsc';

export default function DocumentRow({ doc }) {

  const location = useLocation();
  const docRef = useRef();
  function renderIcon(type) {
    if (type === "publication") return (<IoNewspaperOutline size={24} />)
    if (type === "trial") return (<IoFlaskOutline size={25} />)
    return (<VscFile size={24} />)
  }

  function renderRow() {
    return (
      <div id={doc._id} ref={docRef} className={styles.container} style={{ animationDelay: .08 * doc.index + 's'}}>
        <div className={styles.icon}>
          {renderIcon(doc.type)}
        </div>
        <div className={styles.title}>
          <p>{doc.title.length >= 90 ? `${doc.title.slice(0, 90)} ...` : doc.title }</p>
        </div>
        <div className={styles.info}>
          <p>{doc?.authors?.length > 0 ? doc.authors?.length : 0 } authors</p>
        </div>
        <div className={styles.info}>
          <p>{format(new Date(doc.publicationDate) , 'yyyy/MM/dd')}</p>
        </div>
      </div>
    ); 
  }

  return (
    <>
    {doc.type === "intern" ?
      <Link to={`/library/intern/${doc._id}/${location.search}`}>
        {renderRow()}
      </Link>
      :
      <a href={doc.url} target="_blank" rel="noreferrer">
        {renderRow()}
      </a >
    }
    </>
  )
}
