import React from 'react'
import { format } from 'date-fns'
import styles from './Card.module.scss';

export default function Card({ doc }) {
  return (
    <a href={doc.url} target="_blank" rel="noreferrer" className={styles.container}>
      <p>{doc.title.length >= 70 ? `${doc.title.slice(0, 70)} ...` : doc.title }</p>
      <div className={styles.info}>
        <p>{doc?.authors?.length > 0 ? doc.authors?.length : 0 } authors</p>
        <p>{format(new Date(doc.publicationDate) , 'yyyy/MM/dd')}</p>
      </div>
    </a>
  )
}
