import React , { useRef } from 'react';
import { useLocation , useHistory  } from 'react-router-dom';
//Style
import styles from './Geography.module.scss';

//golbal context
import { useGlobalContext } from '../../context/GlobalContext';

//Components
import Map from '../../components/partials/Map/Map';
import DatavizLoader from '../../components/partials/DatavizLoader/DatavizLoader';

//Utils
import { setQueryUrl } from '../../utils/utils';

export default function GeographyPage() {

  const context  = useGlobalContext()[0];

  const containerRef = useRef();
  const { pathname } = useLocation();
  const history = useHistory();

  const { query } = context.records;
  const locations = context.dataviz?.locations;

  function handleChangeCity(city) {
    let data = query
    let newList = data.cities ? [...data.cities] : [];
    if (newList.find(d => d === city )) {
      newList = data?.cities.filter(d => d !== city );
    } else {
      newList.push(city)
    }
    data.cities = newList.length ? newList : null;
    history.push(pathname + setQueryUrl(data) )
  }

  return (
    <div ref={containerRef} className={`page-container ${styles['page-container']}`}>
      <DatavizLoader isLoading={context.dataviz.isLoading} />
      {containerRef?.current?.clientHeight && locations?.countries && 
        <Map
          data={locations}
          enableZoom={true}
          handleChangeCity={handleChangeCity}
          mapWidth={containerRef?.current?.clientWidth}
          mapHeight={containerRef?.current?.clientHeight}
          mapId={"geography-page"} />
      }
    </div>
  )
}