import React from 'react';
import { BiLoader } from 'react-icons/bi';
import styles from './InputFile.module.scss';

export default function InputFile({ onChange , name , label , btnText , ref , isLoading , type , withErrors , error  }) {

  return (
    <div className={`${styles.container} ${type? styles[type] : ""}`}>
      <label>{label}</label>
      {isLoading ?
        <label className={styles.btn}>
          <div className={styles.loader}><BiLoader size={20} /></div>
        </label>
      :
        <label htmlFor={name} className={styles.btn}>
          {btnText}
        </label>
      }
      <input ref={ref ? ref : null } type="file" name={name} onChange={(e) => onChange(e.target.files) } id={name} />
      {withErrors && <p className={styles['error-message']}>{error && error}</p>}
    </div>
  )
}
