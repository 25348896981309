import React , { useCallback , useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useSpring, animated } from 'react-spring';
import { MdClose } from 'react-icons/md';
import { AiOutlineFileText } from 'react-icons/ai';
import { useGlobalContext } from '../../context/GlobalContext';
import { getTopicsAction, postTopicsAction } from '../../actions/recordsActions';
import { setQueryUrl } from '../../utils/utils';

import Loader from '../../components/lib/Loader/Loader';

import styles from './Topics.module.scss';
import Button from '../../components/lib/Button/Button';
import CustomSelect from '../../components/lib/Select/CustomSelect';
import { useState } from 'react';

export default function TopicsPage() {

  const [ context , dispatch ] = useGlobalContext();

  const history = useHistory();

  const { topics , query } = context.records;
  const [ filters, setFilters ] = useState({ topics: []});
  const [ datesOptions, setDatesOptions ] = useState([]);
  // const authorsCount = useSpring({ number: topics?.authorsCount || 0 , from: { number: 0 } });
  const recordsCount = useSpring({ number: topics?.recordsCount || 0 , from: { number: 0 } });

  function getDatesOptions(start, end) {
    const startDate = new Date(new Date(start).getFullYear(), 0, 1);
    const endDate = new Date(new Date(end).getFullYear() + 1, 0, 1);
    let currentDate = startDate;
    const dates = [];
    do {
      const isoDate = new Date(currentDate.getTime() - (currentDate.getTimezoneOffset() * 60000)).toISOString();
      dates.push({ value: isoDate , label: currentDate.getFullYear() });
      currentDate = new Date(new Date(currentDate).getFullYear() + 1, 0, 1);
    } while (currentDate?.getTime() <= endDate?.getTime());

    setDatesOptions(dates);
    if (!filters.start && !filters.end) {
      setFilters({ ...filters , start: dates[0]?.value , end: dates[dates.length - 1]?.value })
    }

  }

  const initData = useCallback(() => {
    getTopicsAction(dispatch);
    dispatch({
      type: 'RESET_RECORDS',
    });
    dispatch({
      type: 'GET_AUTHOR',
      payload: null
    });
  },[dispatch]);

  useEffect(() => {
    initData();
  }, [initData]);


  useEffect(() => {
    if (topics?.newest?.publicationDate && topics?.oldest?.publicationDate && datesOptions.length === 0) {
      getDatesOptions(topics?.oldest?.publicationDate , topics?.newest?.publicationDate);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [topics?.newest?.publicationDate,topics?.oldest?.publicationDate]);

  useEffect(() => {
    if (filters?.start && filters.end) {
      postTopicsAction(dispatch, filters);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters]);

  function submitSearch() {
    let data = query;
    data.search = null;
    data.topic = filters.topics;
    data.type = ["publication", "trial", "intern"];
    data.keywords = [];
    data.countries=[];
    data.cities=[];
    data.authors =[];
    data.sortBy = "publicationDate";
    data.start =  filters.start;
    data.end = filters.end;
    data.page = 1;
    data.number = 20;
    history.push('/library/' + setQueryUrl(data));
  }
  const options = topics?.topics?.map((opt) => ({ value: opt.label, label: opt.name })).filter((opt) => !filters.topics.find((t) => t === opt.value))
  return (
    <div className={`page-container ${styles.container}`}>
      {options && filters?.start
        && <div className={styles.topics}>
          <h1>Choose your subjects</h1>
          <div className={styles.search}>
            <CustomSelect
              isSearchable
              options={options}
              handleChange={(opt) => setFilters({...filters, topics: [...filters.topics, opt.value]})}
            />
            <div className={styles.list}>
              {filters.topics.map((t) => 
                <div key={t.value} className={styles.topic}>
                  <p>{topics?.topics?.find((opt) => opt.label === t)?.name}</p>
                  <button
                    onClick={() => setFilters({...filters, topics: filters.topics.filter((s) => s !== t)})}
                  >
                    <MdClose />
                  </button>
                </div>
              )}
            </div>
          </div>
          <div className={styles.dates}>
            <div className={`${styles.col} ${styles.count}`}>
              <AiOutlineFileText/>
              <p>{filters.topics.length && <animated.span>{recordsCount.number.interpolate(x => x.toFixed(0))}</animated.span>}</p>
            </div>
            <div className={`${styles.col} ${styles.count}`}>
              {/* <AiOutlineUser />
              <p>{filters.topics.length && <animated.span>{authorsCount.number.interpolate(x => x.toFixed(0))}</animated.span>}</p> */}
            </div>
            <div className={styles.col}>
              <CustomSelect
                isSearchable
                options={datesOptions}
                secondary
                defaultValue={datesOptions?.find((opt) => opt.value === filters.start)}
                handleChange={(opt) => setFilters({...filters, start: opt.value })}
              />
            </div>
            <div className={styles.col}>
              <CustomSelect
                isSearchable
                options={datesOptions}
                secondary
                defaultValue={datesOptions?.find((opt) => opt.value === filters.end)}
                handleChange={(opt) => setFilters({...filters, end: opt.value })}
              />
            </div>
          </div>
          <div>
            <Button
              primary
              onClick={() => submitSearch()}
              className={`${styles.btn} ${filters.topics.length === 0 ? styles.disabled : ''}`}
              >
              <span>Explore data</span>
            </Button>
          </div>
        </div>
      }
      {!topics && 
        <div className={`${styles.loader}`}>
          <Loader />
        </div>
      }
    </div>
  )
}
