export function setVhUnit() {
  let vh = window.innerHeight * 0.01;
  document.documentElement.style.setProperty('--vh', `${vh}px`);
  window.addEventListener('resize', () => {
    let vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty('--vh', `${vh}px`);
  });
}

export const hexToRgba = (hex, alpha = 1) => {
  let r = parseInt(hex.slice(1, 3), 16),
      g = parseInt(hex.slice(3, 5), 16),
      b = parseInt(hex.slice(5, 7), 16);

  return "rgba(" + r + ", " + g + ", " + b + ", " + alpha + ")";

}

export const formatDate = (val) => {
  let date = new Intl.DateTimeFormat('fr', { year: 'numeric' , month: 'long' }).format(new Date(val));
  return date;
}

export const setQueryUrl = (obj) => {
  let params = new URLSearchParams()
  for (const key in obj) {
    if(obj[key]) {
      if (Array.isArray(obj[key])) {
        params.append(key, obj[key].join(','))
      } else {
        params.append(key, obj[key]);
      }
    }
  }
  return "?" + params.toString();
}