import React , { useEffect , useCallback , useState } from 'react'
import { IoClose } from 'react-icons/io5'
//Contect & actions 
import { useGlobalContext } from '../../../../../context/GlobalContext'
import { postFileAction , deleteFileAction } from '../../../../../actions/recordsActions'
import { API_URL } from '../../../../../actions/index'

//Components
import InputFile from '../../../../../components/lib/InputFile/InputFile'

//Styles
import styles from './PicturesList.module.scss';

export default function PicturesList({ isPreview , name ,  list , handleChange }) {

  const [ context,  dispatch ] = useGlobalContext();

  const { file , error } = context.records;

  const [ uploadedFile , setUploadedFile ] = useState();
  const [ fileError , setFileError ] = useState();
  const [ picturesList , setPicturesList ] = useState([]);

  const clearFile = useCallback(() => {
    dispatch({
      type : 'POST_FILE',
      payload : null,
    });  
  },[dispatch])

  useEffect(() => {
    clearFile();
    return () => {
      clearFile();
    }
    // eslint-disable-next-line 
  },[]);

  useEffect(() => {
    if(list[0]?._id) {
      setPicturesList(list)
    }
  },[list])

  useEffect(()=> {
    if (file?._id && uploadedFile) {
      let updatedList = [...list.map(p => p._id)]
      updatedList.push(file._id);
      if (uploadedFile) document.getElementById("input" + name).value = "";
      setUploadedFile();
      setFileError();
      dispatch({
        type : 'POST_FILE',
        payload : null,
      });  
      return handleChange(updatedList)
    }
    // eslint-disable-next-line
  },[file]);

  useEffect(()=> {
    if (error && error.data && error.data.error ) {
      if (error.data.error === "Extension not allowed.") {
        setFileError("Extension not allowed.");
        setUploadedFile();
        dispatch({
          type : 'ERROR_RECORDS',
          payload : null,
        });    
      }
    }
    // eslint-disable-next-line
  },[error]);

  function handleChangeFile(fileData ) {

    dispatch({
      type : 'ERROR_RECORDS',
      payload : null,
    });

    setUploadedFile(fileData);
    setFileError();

    const formData = new FormData();
    formData.append("file", fileData );
    formData.append("title", fileData.name );
    formData.append("description", `document ${fileData.name}`);
    formData.append("alt", `${fileData.name.split(" ").join("-")}-file`);
    postFileAction(dispatch , formData )

  }

  function deleteFile(id) {
    deleteFileAction(dispatch , id );
    let deletedList = [...list.map(p => p._id).filter(p => p !== id)]
    return handleChange(deletedList)
  }

  return (
    <div className={styles.container}>
      <div className={styles.list}>
        {picturesList.map((p) => 
          <div key={"picture-" + p._id}>
            {!isPreview && p?.path && 
              <button
                onClick={() => deleteFile(p._id)}
                >
                <IoClose size={18}/>
              </button>
            } 
            {p?.path && <img src={`${API_URL}/${p?.path}`} alt={p.alt} />}
          </div>
        )}
      </div>
      {!isPreview &&
        <div className={styles.input}>
          <InputFile
            name={"input" + name}
            isLoading={uploadedFile}
            btnText={"Add a picture"}
            withErrors={false}
            defaultValue={null}
            onChange={(files) => handleChangeFile(files[0])}
            error={fileError}
            />
        </div>
      }
    </div>
  )
}
