import React , { useEffect , useState , useRef } from 'react';
import { useHistory , Link , useLocation } from 'react-router-dom';
import { useSpring, animated } from 'react-spring';
//Style
import styles from './Library.module.scss';

//golbal context
import { setQueryUrl } from '../../utils/utils';
import { useGlobalContext } from '../../context/GlobalContext';

//Components
import DocumentRow from '../../components/partials/DocumentRow/DocumentRow';
import ReadingGuideRow from '../../components/partials/ReadingGuideRow/ReadingGuideRow';
import Button from '../../components/lib/Button/Button';
import Modale from '../../components/lib/Modale/Modale';
import DatavizLoader from '../../components/partials/DatavizLoader/DatavizLoader';
import DocumentModale from '../../components/partials/DocumentModale/DocumentModale';

export default function LibraryPage() {

  const [ context , dispatch ] = useGlobalContext();
  const history = useHistory();
  const location = useLocation();
  const { query , count , dates , recordsList , libraryIsLoading , libraryView } = context.records;
  const { readingGuidesList } = context.readingGuides;

  const readingCount  = context.readingGuides.count 

  const { isAdmin } = context.auth
  const containerRef = useRef(null);
  const scrollRef = useRef(null);

  const recordsCount = useSpring({ number: count , from: { number: 0 } });
  const readingGuidesCount = useSpring({ number: readingCount , from: { number: 0 } });

  const [ scrollTop , setScrollTop ] = useState(0);
  // const [ activeList , setLibraryView ] = useState("documents")
  const [ activeModale , setActiveModale ] = useState();

  useEffect(() => {
    return () => setActiveModale();
  }, []);

  useEffect(() => {
    if (context.records.error) {
      dispatch({
        type: 'RESET_RECORDS',
      });
      if (context.records.error.status === 500 ) {
        dispatch({
          type: 'SET_TOAST',
          payload: {
            message: "une erreur du serveur interne s'est produite",
            type: "error"
          }
        });
      } 
    }
    // eslint-disable-next-line
  },[context.records.error])

  useEffect(() => {
    if (query.page === 1) {
      containerRef.current.scrollTo({top: 0, behavior: 'smooth'});
      setScrollTop(0);
    }
  },[query])

  function handleScroll(e) {
    if (!libraryIsLoading && e.target.scrollTop > scrollTop && scrollRef?.current.getBoundingClientRect().bottom <= containerRef?.current.getBoundingClientRect().bottom ) {
      setScrollTop(e.target.scrollTop)
      let data = {...query};
      data.page = data.page + 1
      dispatch({
        type: 'LIBRARY_IS_LOADING',
        payload: true
      })
      history.push('/library/' + setQueryUrl(data) )
    }
  }

  function setLibraryView(val) {
    dispatch({
      type: 'SET_LIBRARY_VIEW',
      payload : val
    });
  }

  return (
    <>
      <div id="library-page" className={`page-container ${styles['page-container']}`}>
        <DatavizLoader isLoading={libraryIsLoading || !dates} />
        <header className={styles.header}>
          <div className={styles.content}>
            <div className={styles.links}>
              <h2
                className={`${libraryView ===  "documents" ? styles.active : ""}`}
                onClick={() => setLibraryView("documents")}
                >
                Documents
              </h2>
              <h2
                className={`${libraryView ===  "reading-guides" ? styles.active : ""}`}
                onClick={() => setLibraryView("reading-guides")}
                >
                Reading guides
              </h2>
            </div>
            {libraryView ===  "documents" ?
              <div className={`${styles.counter}`}>
                <p><animated.span>{recordsCount.number.interpolate(x => x.toFixed(0))}</animated.span> Documents</p>
                {isAdmin &&
                  <Button
                    primary
                    onClick={() => setActiveModale('document')}
                    >
                    New document
                  </Button>
                }
              </div>
              :
              <div className={`${styles.counter}`}>
              <p><animated.span>{readingGuidesCount.number.interpolate(x => x.toFixed(0))}</animated.span> Documents</p>
                {isAdmin &&
                <Link to={'/library/reading-guide' + location.search }>
                  <Button
                    primary
                    onClick={() => setActiveModale('document')}
                    >
                    New reading guide
                  </Button>
                </Link>
                }
              </div>
            }
          </div>
        </header>
        <div
          className={`${styles.container}`}
          onScroll={(e) => handleScroll(e)}
          ref={containerRef}
          >

          <div className={`content ${styles.content}`}>
            <div
            ref={scrollRef}
            >
            {libraryView === "documents" && recordsList?.map((doc , i ) => {
                return <DocumentRow key={"doc" + doc._id } index={i} doc={doc} />
              }
            )}
            {libraryView === "reading-guides" && readingGuidesList?.map((doc , i ) => {
                return <ReadingGuideRow key={"doc" + doc._id } index={i} doc={doc} />
              }
            )}
            </div>
          </div>

        </div>
      </div>
      {libraryView === "documents" &&
        <Modale
          isOpen={activeModale}
          modaleToggle={setActiveModale}
          hideClose={true}
          >
          {isAdmin && activeModale === "document" && <DocumentModale
            setActiveModale={setActiveModale}
            />}
        </Modale>
      } 
    </>
  )
}