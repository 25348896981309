import React , { useEffect , useState , useCallback } from 'react';
import { useForm  , Controller } from "react-hook-form";
import { AiFillDelete } from 'react-icons/ai';
import { API_URL } from '../../../actions/index';

import { useGlobalContext } from '../../../context/GlobalContext';

import { postFileAction , putRecordAction , deleteFileAction , postRecordAction, getTopicsAction } from '../../../actions/recordsActions';

import Input from '../../lib/Input/Input';
import Button from '../../lib/Button/Button';
import CustomSelect from '../../lib/Select/CustomSelect';
import TagsSelect from '../../lib/TagsSelect/TagsSelect';
import InputFile from '../../lib/InputFile/InputFile';
import Textarea from '../../lib/Textarea/Textarea';
import DatePicker from '../../lib/DatePicker/DatePicker';

import styles from './DocumentModale.module.scss';

export default function DocumentModale({ documentData , setActiveModale  }) {

  const [ context,  dispatch ] = useGlobalContext();

  const { handleSubmit, register, errors , setValue , control } = useForm();

  const { file , error , topics , optionsLists } = context.records;

  const topicsOptions = topics?.topics?.map(d => ({ value: d.label , label: d.name }));
  const tagsOptions = optionsLists?.keywords?.map(d => ({ value: d , label: d }));

  const [ uploadedFile , setUploadedFile ] = useState();
  const [ fileError , setFileError ] = useState();
  const [ fileId , setFileId ] = useState();

  const getData = useCallback(() => {
    getTopicsAction(dispatch);
  },[dispatch]);

  const clearFile = useCallback(() => {
    dispatch({
      type : 'POST_FILE',
      payload : null,
    });  
  },[dispatch]);

  useEffect(() => {
    clearFile();
    getData();
    return () => {
      clearFile();
    }
    // eslint-disable-next-line 
  },[]);

  useEffect(() => {
    register("publicationDate");
    register("topic");
    register('file');
  }, [register]);

  useEffect(() => {
    if (documentData?.file) setValue('file' , documentData.file );
    if (documentData?.topic)  setValue('topic' , documentData.topic );
  }, [documentData , setValue])

  useEffect(() => {
    if (errors.file && errors.file.message) {
      setFileError(errors.file.message);
    }
  },[errors.file]);

  useEffect(()=> {
    if (file && file._id) {
      setValue('file' , file._id );
      setFileId(file._id);
      if (uploadedFile) document.getElementById('file').value = "";
      setUploadedFile();
      setFileError();
    }
    // eslint-disable-next-line
  },[file]);

  useEffect(()=> {
    if (error && error.data && error.data.error ) {
      if (error.data.error === "Extension not allowed.") {
        setFileError("Extension not allowed.");
        setUploadedFile();
        setFileId();
        dispatch({
          type : 'ERROR_RECORDS',
          payload : null,
        });    
      }
    }
    // eslint-disable-next-line
  },[error]);

  function handleChangeFile(fileData ) {

    dispatch({
      type : 'ERROR_RECORDS',
      payload : null,
    });

    setUploadedFile(fileData);
    setFileError();
    setFileId();

    const formData = new FormData();
    formData.append("file", fileData );
    formData.append("title", fileData.name );
    formData.append("description", `document ${fileData.name}`);
    formData.append("alt", `${fileData.name.split(" ").join("-")}-file`);
    postFileAction(dispatch , formData );

  }


  function deleteFile(id) {
    deleteFileAction(dispatch , id );
    setValue('file' , null )
    setFileId();
  }

  function closeModale() {
    setActiveModale(false);
    if (fileId) deleteFile(fileId);
  }

  function submitForm(values) {
    if (!file?._id && !documentData ) return setFileError("file required");
    if(documentData?._id) {
      putRecordAction(dispatch , documentData?._id , values );
    } else {
      postRecordAction(dispatch , values );
    } 
    return setActiveModale(false);
  }

  function handleChangeTopic(opt) {
    setValue('topic' , opt.value);
  }

  return (
    <div className={styles.container}>
      <h2>{documentData?._id ? "Edit the document" : "Add a document"}</h2>
      <form onSubmit={handleSubmit(submitForm)}>

        <Input
          label={"Title"}
          placehloder={"Title"}
          name={"title"}
          defaultValue={documentData?.title ? documentData.title : '' }
          register={register({
            required: "required",
          })}
          withErrors={true}
          error={errors.title && errors.title.message}
        />

        <Textarea
          label={"Description"}
          placehloder={"Description"}
          name={"content.description"}
          register={register({
            required: "required",
          })}
          defaultValue={documentData?.content?.description ? documentData.content.description : '' }
          withErrors={true}
          error={errors?.content?.description?.message}
        />

        <Controller
          name="topic"
          rules={{ required: "required" }}
          control={control}
          render={(props) =>
            <CustomSelect
              label={"Topic"}
              defaultValue={topicsOptions?.find(o => o.value === documentData?.topic )}
              handleChange={(opt) => handleChangeTopic(opt)}
              options={topicsOptions}
              withErrors={true}
              error={errors.topic && errors.topic.message}
              />
          }
          defaultValue={null}
        />

        <label>File</label>

        <div className={styles['input-file']}>
          <Controller
            name="file"
            rules={{ required: "required" }}
            control={control}
            defaultValue={null}
            render={(props) =>
              <InputFile
                name="file"
                isLoading={uploadedFile}
                btnText={documentData?._id ? "Select a file" : "Add a file"}
                withErrors={false}
                defaultValue={null}
                onChange={(files) => handleChangeFile(files[0])}
                error={fileError}
                />}
          />
        </div>

        {file && file._id  &&
          <div className={styles.file}>
            <a href={`${API_URL}/${file.path}`} rel="noreferrer" target="_blank">
              <div className={styles.link}>
                <p>{file.name}</p>
              </div>
            </a>
            <button
            type="button"
            onClick={() => deleteFile(fileId)}
            className={`${styles.delete}`}>
            <AiFillDelete size={20} color={'red'} />
            <p>delete</p>
          </button>
          </div>
        }

        <p className={styles.error}>{fileError && fileError}</p>

        <Controller
          name="keywords"
          control={control}
          defaultValue={documentData?.keywords ? documentData.keywords : null }
          render={() =>
            <TagsSelect
              label="Keywords"
              defaultValue={documentData?.keywords ? documentData.keywords.map(t => ({ value: t , label: t })) : null }
              tagsOptions={tagsOptions}
              withErrors={true}
              onChange={(val) => setValue( 'keywords' , val )}
              error={errors.keywords && errors.keywords.message}
              />
          }
        />

        <Controller
          name="publicationDate"
          control={control}
          defaultValue={documentData?.publicationDate ? documentData.publicationDate : new Date().toISOString() }
          render={() =>
          <DatePicker
            label={"Publictaion date"}
            id={`data-picker-publicationDate`}
            withErrors={true}
            date={documentData?.publicationDate ? new Date(documentData?.publicationDate) : new Date() }
            handleChange={(val) => setValue("publicationDate", val.toISOString())}
            />
          }
        />

        <div className={styles.actions}>
          <Button
            type={"submit"}
            primary
            >
            Submit
          </Button>
          <button
            className={styles.cancel}
            type="button"
            onClick={() => closeModale()}
            >
            Cancel
          </button>
        </div>
      </form>
    </div>
  )
}
