import { getData , postData , putData , deleteData , getFile } from './index';

import {
  ERROR_RECORDS,
  GET_OPTIONS_LISTS,
  GET_RECORDS,
  GET_TOPICS,
  GET_DATES,
  POST_RECORD,
  PUT_RECORD,
  GET_FILE_PREVIEW ,
  DELETE_RECORD,
  GET_RECORD,
  GET_AUTHORS,
  GET_AUTHOR,
  POST_FILE,
  DELETE_FILE
} from "./types"

export async function getTopicsAction(dispatch){
  let url = "/record/topic";
  var topics;

  await getData(ERROR_RECORDS, url, dispatch , true ).then((response)=>{
    topics = response
  });
  
  dispatch({
    type : GET_TOPICS,
    payload : topics
  });
  
};

export async function postTopicsAction(dispatch , data){
  let url = "/record/topic";
  var topics;

  await postData(GET_TOPICS , ERROR_RECORDS , url, dispatch , data, true ).then((response)=>{
    topics = response
  });
  
  dispatch({
    type : GET_TOPICS,
    payload : topics
  });
  
};


export async function getDatesAction(dispatch){
  let url = "/record/date";
  var dates;

  await getData(ERROR_RECORDS, url, dispatch , true ).then((response)=>{
    dates = response
  });
  
  dispatch({
    type : GET_DATES,
    payload : dates
  });
  
};

export async function postOptionsListsAction(dispatch , obj ){
  let url = "/record/list";
  var lists;
  let  data = {...obj}
  
  await postData(GET_OPTIONS_LISTS , ERROR_RECORDS , url, dispatch , data, true ).then((response)=>{
    lists = response.lists
  });
  
  dispatch({
    type : GET_OPTIONS_LISTS,
    payload : lists
  });
  
};

export async function getRecordsFilteredAction(dispatch , obj){
  let url = "/record";
  var records;

  let  data = {...obj}
  // if (data?.authors?.length > 0 ) {
  //   data.authors = data.authors.map(a => {
  //     let author = a.split('/');
  //     return ({ foreName : author[0] , lastName : author[1]})
  //   });
  // }

  await postData(GET_RECORDS , ERROR_RECORDS , url, dispatch , data , true ).then((response)=>{
    records = response
  });

  dispatch({
    type : GET_RECORDS,
    payload : {
      records: records.records,
      count: records.count,
      query: obj
    }
  });
};

export async function getAuthorsFilteredAction(dispatch , obj){
  let url = "/record/authors";
  var authors;

  let  data = {...obj}
  // if (data?.authors?.length > 0 ) {
  //   data.authors = data.authors.map(a => {
  //     let author = a.split('/');
  //     return ({ foreName : author[0] , lastName : author[1]})
  //   });
  // }

  await postData(GET_AUTHORS , ERROR_RECORDS , url, dispatch , data , true ).then((response)=>{
    authors = response
  });

  dispatch({
    type : GET_AUTHORS,
    payload : {
      authors: authors.authors,
      count: authors.count,
      query: obj
    }
  });
};

export async function getAuthorAction(dispatch , data){
  let url = "/record/author";
  var author;

  await postData(GET_AUTHOR , ERROR_RECORDS , url, dispatch , data , true ).then((response)=>{
    author = response.author;
  });

  dispatch({
    type : GET_AUTHOR,
    payload : author,
  });
};

export async function postRecordAction(dispatch , obj ){
  let url = "/record/new";
  var record;

  await postData(POST_RECORD , ERROR_RECORDS , url, dispatch , obj, true ).then((response)=>{
    record = response.record
  });

  dispatch({
    type :  'SET_TOAST',
    payload : {
      type: "success",
      message: "the document has been added"
    }
  });

  dispatch({
    type : POST_RECORD,
    payload : record
  });
  
};

export async function putRecordAction(dispatch , id , data ) {
  let url = "/record/" + id;
  var record;

  await putData(PUT_RECORD, ERROR_RECORDS ,  url , dispatch, data , true ).then((response)=>{
    record = response.record
  });

  dispatch({
    type : PUT_RECORD,
    payload : record
  });

  dispatch({
    type :  'SET_TOAST',
    payload : {
      type: "success",
      message: "The document has been updated"
    }
  });
}

export async function getRecordAction(dispatch , id ){
  let url = "/record/id/" + id ;
  var record;

  await getData(ERROR_RECORDS, url, dispatch , true ).then((response)=>{
    record = response.record
  });
  
  dispatch({
    type : GET_RECORD,
    payload : record
  });
  
};

export async function deleteRecordAction(dispatch , id ){
  let url = "/record/" + id;
  let deleted

  await deleteData(ERROR_RECORDS, url, dispatch  ).then((response)=>{
    deleted = response
  });

  if (deleted ) {
    dispatch({
      type :  'SET_TOAST',
      payload : {
        type: "success",
        message: "The document has been removed"
      }
    });
    dispatch({
      type :  DELETE_RECORD,
      payload : id
    });
  }

};

export async function getFileAction(dispatch , id , isPreview = false  ){
  let url = "/file/download/" + id ;
  await getFile(ERROR_RECORDS , url, dispatch , true  ).then((response)=>{
    if (!isPreview) {
      var fileURL = URL.createObjectURL(response);
      window.open(fileURL);
    } else {
      dispatch({
        type : GET_FILE_PREVIEW ,
        payload : URL.createObjectURL(response)
      })
    }
  });
  
};

export async function postFileAction(dispatch , formData ){
  let url = "/file",
      file;

  await postData(POST_FILE , ERROR_RECORDS , url, dispatch , formData , true ).then((response)=>{
    file = response
  });

  dispatch({
    type : POST_FILE ,
    payload : file.files[0]
  });

};


export async function deleteFileAction(dispatch , id ){
  let url = "/file/" + id,
      file;

  await deleteData(ERROR_RECORDS, url, dispatch  ).then((response)=>{
    file = response
  });

  dispatch({
    type : DELETE_FILE ,
    payload : {
      file: null,
      message: `${file.message} ${id}`
    }
  });

};
