import React , { useRef , useEffect , useState } from 'react';
import * as QueryString from "query-string";
import * as d3 from "d3";
import { cloneDeep } from 'lodash';
import { useGlobalContext } from '../../../context/GlobalContext';
import { hexToRgba } from '../../../utils/utils';
import Tooltip from '../Tooltip/Tooltip';
import styles from './Nodes.module.scss';
import { useLocation } from 'react-router-dom';

export default function Nodes({ data , width , height , handleChangeKeywords , handleChangeAuthors }) {

  const graphContainer = useRef(null);
  const { search } = useLocation();
  let queryFilters = QueryString.parse(search);
  const selectedAuthors = queryFilters?.authors?.split(',');

  const grey =  "#D8D8D8";

  const [ tooltipData , setTooltipData ] = useState({ x: width / 2 , y: height / 2 });
  const context = useGlobalContext()[0];

  const { query } = context.records;

  const color = {
    dark: '#05050F',
    white: '#FFFFFF',
    primary: '#02AFAA',
    active: '#E2DE7C',
  }

  useEffect(() => {
    var currentGraph = graphContainer.current
    return () => {
      d3.select(currentGraph).select('canvas').remove();
    }
    // eslint-disable-next-line
  },[]);

  useEffect(() => {
    if (data) {
      let dataset = cloneDeep(data);

      let maxNodeSize = 1;
      let minNodeSize = 1;
      dataset.nodes.forEach((n) => {
        if (n.size > maxNodeSize ) maxNodeSize = n.size;
        if (n.size < minNodeSize ) minNodeSize = n.size;
        n.color  = n.type === "author" ? color.dark : color.primary;
        n._id = n.value;
      });
      dataset.links.forEach((l) => l._id = `${l.source}-${l.targ}`);
  
      var scale = d3.scaleLinear()
      .domain([1, maxNodeSize ])
      .range([8,  40 ]);


  
      dataset.nodes.forEach(n => {
        n.radius = maxNodeSize === 1 ? 10 : scale(n.size);
        n.color = n.type === "author" ? color.dark : color.primary
        for (const key in query) {
          if (query[key] && Array.isArray(query[key])) {
            if (key === "countries" || key === "cities" || key === "keywords") {
              query[key].forEach(d => {
                let found = d === n.value
                if (found) {
                  n.isActive = true;
                }
              });
            }
            if (key === "authors") {
              query[key].forEach(d => {
                let found = selectedAuthors.find((a) => a === n.value);
                if (found) {
                  n.isActive = true;
                }
              });
            }
          }
        }
      });

      initGraph(dataset);
    }
    // eslint-disable-next-line
  },[data]);

  function initGraph(initData){
    var tempData = {...initData}
    var dpr = window.devicePixelRatio || 1;
    var heightDpr = height * dpr;
    var widthDpr =  width * dpr;

    setTooltipData({ x: width / 2 , y: height /2 });

    if (d3.select(graphContainer.current).select('canvas')) d3.select(graphContainer.current).select('canvas').remove();

    var graphCanvas = d3.select(graphContainer.current).append('canvas')
      .classed('graphCanvas', true)
      .attr('id' , "canvas")
      .attr('width', widthDpr + 'px')
      .attr('height', heightDpr + 'px')
      .node();
    
    var context = graphCanvas.getContext('2d');

    context.scale(dpr , dpr);

    var simulation = d3.forceSimulation()
      .force("center", d3.forceCenter((widthDpr / 2 ) / dpr , ((heightDpr / 2) / dpr ) - 30 ))
      .force("x", d3.forceX(widthDpr / 2).strength(0.1))
      .force("y", d3.forceY(heightDpr / 2).strength(0.1))
      .force("charge", d3.forceManyBody().strength(-55))
      .force("link", d3.forceLink().id(function(d) { return d._id; }).strength(0.8).distance(80))
      .force('collision', d3.forceCollide().radius(d => d.radius * 1.8 ))
      .alphaTarget(0)
      .alphaDecay(0.05)
    
    var transform = d3.zoomIdentity;

    function zoomed(event) {
      transform = event.transform;
      simulationUpdate();
    }

    d3.select(graphCanvas)
        .call(d3.drag().subject(dragsubject).on("start", dragstarted).on("drag", dragged).on("end",dragended))
        .call(d3.zoom().scaleExtent([1 / 10, 8]).on("zoom", zoomed));

    function dragsubject(event) {
      var i,
      x = transform.invertX(event.x),
      y = transform.invertY(event.y),
      dx,
      dy;

      for (i = tempData.nodes.length - 1; i >= 0; --i) {
        let node = tempData.nodes[i];
        dx = x - node.x;
        dy = y - node.y;

        if (dx * dx + dy * dy < node.radius * node.radius) {

          node.x =  transform.applyX(node.x);
          node.y = transform.applyY(node.y);

          return node;
        }
      }
    }

    function dragstarted(event) {
      if (!event.active) simulation.alphaTarget(0.3).restart();
      event.subject.fx = transform.invertX(event.x);
      event.subject.fy = transform.invertY(event.y);
    }

    function dragged(event) {
      event.subject.fx = transform.invertX(event.x);
      event.subject.fy = transform.invertY(event.y);
    }

    function dragended(event) {
      if (!event.active) simulation.alphaTarget(0);
      event.subject.fx = null;
      event.subject.fy = null;
    }

    simulation.nodes(tempData.nodes)
              .on("tick", () => window.requestAnimationFrame(simulationUpdate));

    simulation.force("link")
              .links(tempData.links).strength(1);
  
    function simulationUpdate(){
  
      context.save();
      context.clearRect(0, 0, widthDpr, heightDpr );
      context.translate(transform.x, transform.y);
      context.scale(transform.k , transform.k);

      tempData.links.forEach(function(d) {
        context.beginPath();
        context.moveTo(d.source.x, d.source.y);
        context.lineTo(d.target.x, d.target.y);
        context.lineWidth = 1.5 / transform.k;
        context.strokeStyle = grey; 
        context.stroke();
      });

      tempData.nodes.forEach(function(d, i) {
        context.beginPath();
        context.arc(d.x , d.y, d.radius , 0, 2 * Math.PI, true);
        context.fillStyle = d.type === "author" ? color.dark : color.primary;
        context.strokeStyle = "rgba(0, 0, 0, 1)";
        context.fill();
        if (d.isActive) {
          context.beginPath();
          context.arc(d.x, d.y, d.radius + 4, 0, 2 * Math.PI, true);
          context.strokeStyle = hexToRgba(d.type === "author" ? color.dark : color.primary , 0.4);
          context.lineWidth = 8;
          context.stroke();
        }
      });

      if (closeNode) {
        d3.select(`.${styles.graph}`).style('cursor' , 'pointer');
        context.beginPath();
        context.arc(closeNode.x, closeNode.y, closeNode.radius + 4, 0, 2 * Math.PI, true);
        context.strokeStyle = hexToRgba(closeNode.color , 0.4);
        context.lineWidth = 8;
        context.stroke();
      } else {
        d3.select(`.${styles.graph}`).style('cursor' , 'auto');
      }

      context.restore();

    }

    var closeNode;
    
    d3.select(graphCanvas)
      .on("click", function(event , d ){
        event.preventDefault();
        event.stopPropagation();
        closeNode = foundNode(event) ? foundNode(event) : null ;
        if (closeNode?.type === "keyword") handleChangeKeywords(closeNode.value);
        if (closeNode?.type === "author") handleChangeAuthors(closeNode.value);
        simulationUpdate();
      })
      .on("mousemove", function(event){
        event.preventDefault();
        event.stopPropagation();
        closeNode = foundNode(event) ? foundNode(event) : null ;
        if (closeNode) {
          setTooltipData({...closeNode, x: event.x - 70 , y: event.y - 80 });
        } else {
          setTooltipData({ x: event.x - 70 , y: event.y - 120 });
        }
        simulationUpdate();
      })
    
    function foundNode(event) {
      let posX = (event.x - transform.x) / transform.k;
      let posY = (event.y - 80 - transform.y) / transform.k ;
      return simulation.find( posX , posY , 60 );
    }

  }

  return (
    <div ref={graphContainer} className={styles.graph}>
      <Tooltip data={tooltipData} type={"datavision"}/>
    </div>
  )
}